import axios from "axios";
import { toast } from "react-toastify";
import { API_DOMAIN } from "./GlobalConst";

const ethers = require("ethers");
// export const buytoken = (fileid, contractAddress, qty, price, ) => {
//   var API_URL = "https://tnt.moramba.io:8888/contracts/" + fileid + ".json";
//   axios
//     .get(API_URL)
//     .then(async (response) => {
//       console.log("00000 ", response.data);
//       var contractAbi = response.data.abi;
//       //contractAddress="0xAa29157AeA2a2Fd3B8d256861Ca263226E41cf1b";
//       const provider = new ethers.BrowserProvider(window.ethereum);
//       await provider.send("eth_requestAccounts", []);
//       const signer = await provider.getSigner();
//       const contract = new ethers.Contract(
//         contractAddress,
//         contractAbi,
//         signer
//       );
//       const txResponse = await contract.buyMinted(qty, { value: price });
//       await txResponse.wait(); // Wait for the transaction to be mined
//       await sessionStorage.setItem("closepopup",true)
//       // await buyhandle(contractAddress, fileid);
//       console.log("Transaction Hash:", txResponse.hash);
//     })
//     .catch((error) => {
//       console.log(error);
//       alert(error);
//     });
// };
//  const buyhandle = (contractAddress, fileid) => {
//    var API_URL = API_DOMAIN + `mycontract/create`;
//    let headerConfig = {
//      headers: {
//        accept: "application/json",
//      },
//    };
//    var data = {
//      userid: sessionStorage.getItem("userid"),
//      contractaddress: contractAddress,
//      fileid: fileid,
//    };
//    axios
//      .post(API_URL, data, headerConfig)
//      .then((response) => {
//        console.log("buy ", response);
//      })
//      .catch((error) => {
//        toast.error(error.response.data.message);
//      });
//  };
export const getAvailabeToken = (fileid, contractAddress) => {
  var API_URL = "https://tnt.moramba.io:8888/contracts/" + fileid + ".json";
  axios
    .get(API_URL)
    .then(async (response) => {
      console.log("00000 ", response.data);
      var contractAbi = response.data.abi;
      //contractAddress="0xAa29157AeA2a2Fd3B8d256861Ca263226E41cf1b";
      const provider = new ethers.BrowserProvider(window.ethereum);
      
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        contractAddress,
        contractAbi,
        signer
      );
      const txResponse = await contract.getBalanceToken();
      sessionStorage.setItem("token_balance", txResponse);
      console.log("", txResponse);
    })
    .catch((error) => {
      console.log(error);
    });
};
// export const getdetails = (tokenid) => {
//   var API_URL = "https://tnt.moramba.io:8888/contracts/" + fileid + ".json";
//   axios
//     .get(API_URL)
//     .then(async (response) => {
//       console.log("00000 ", response.data);
//       var contractAbi = response.data.abi;
//       const provider = new ethers.BrowserProvider(window.ethereum);
//       await provider.send("eth_requestAccounts", []);
//       const signer = await provider.getSigner();
//       const escrow = new ethers.Contract(
//         contractAbi.address,
//         contractAbi.abi,
//         signer
//       );
//     let txResponse = await escrow.getAllTokens(tokenid);

//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };