import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import "../Homepage/HomePage.css";
// import img1 from "../../Assets/images/carousel/crs-4.jpg";
import img1 from "../../Assets/images/tntimages/TNT-gif.gif";
import img2 from "../../Assets/images/carousel/crs-10.jpg";
import img3 from "../../Assets/images/tntimages/53cab42a-dd1f-4d54-a876-274640fa14e1_1024x1024.jpg";
import img4 from "../../Assets/images/carousel/crs-11.jpg";
// import img5 from "../../Assets/images/items/anim-8.webp";
import img5 from "../../Assets/images/tntimages/Logo.png";
// import img5 from "../../Assets/images/tntimages/hero_11-p-500.jpg";
import author1 from "../../Assets/images/author/author-1.jpg";
import static1 from "../../Assets/images/items-alt/static-1.jpg";
import coll1 from "../../Assets/images/collections/coll-1.jpg";
import coll2 from "../../Assets/images/collections/coll-2.jpg";
import coll3 from "../../Assets/images/collections/coll-3.jpg";
import coll4 from "../../Assets/images/collections/coll-4.jpg";
import coll5 from "../../Assets/images/collections/coll-5.jpg";
import coll6 from "../../Assets/images/collections/coll-6.jpg";
import { FaCheck, FaEnvelope, FaFacebookF, FaTwitter } from "react-icons/fa";
import { BsWallet2 } from "react-icons/bs";
import { MdOutlineCloudUpload } from "react-icons/md";
import { AiOutlineTags } from "react-icons/ai";
import { FaRegHeart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import $ from "jquery";
import Card from "../Card/Card";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { API_DOMAIN } from "../../utils/GlobalConst";
function HomePage() {
  const navigate = useNavigate();
  const [HomeMainData, setHomeMainData] = useState([]);
  useEffect(() => {
    var API_URL = API_DOMAIN + "image/selectall";

    axios
      .get(API_URL)
      .then((response) => {
        console.log("all cards ", response.data.data);
        var res = response.data.data;
        const sortdata = res.imageData;
        sortdata.sort(
          (a, b) => new Date(a?.StartDate) - new Date(b?.StartDate)
        );
        console.log("sortdata", sortdata);
        const finaldata = [];
        for (let i = 0; i < sortdata.length; i++) {
          if (i <= 5) {
            finaldata.push(sortdata[i]);
          }
        }
        console.log(finaldata);
        setHomeMainData(finaldata);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, []);
  const responsiveSettings = [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ];

  const CollectionData = [
    {
      img: coll1,
      authorimg: author1,
      title: "Abstraction",
      erc: "ERC-192",
    },
    {
      img: coll2,
      authorimg: author1,
      title: "Patternlicious",
      erc: "ERC-61",
    },
    {
      img: coll3,
      authorimg: author1,
      title: "Skecthify",
      erc: "ERC-126",
    },
    {
      img: coll4,
      authorimg: author1,
      title: "Cartoonism",
      erc: "ERC-73",
    },
    {
      img: coll5,
      authorimg: author1,
      title: "Virtuland",
      erc: "ERC-85",
    },
    {
      img: coll6,
      authorimg: author1,
      title: "Papercut",
      erc: "ERC-42",
    },
  ];

  useEffect(() => {
    $(".nft__item_click").on("click", function () {
      var iteration = $(this).data("iteration") || 1;

      switch (iteration) {
        case 1:
          var cover = $(this).parent().parent().find(".nft__item_extra");
          cover.css("visibility", "visible");
          cover.css("opacity", "1");
          break;
        case 2:
          var cover = $(this).parent().parent().find(".nft__item_extra");
          cover.css("visibility", "hidden");
          cover.css("opacity", "0");
          break;
      }
      iteration++;
      if (iteration > 2) iteration = 1;
      $(this).data("iteration", iteration);
    });

    $(".nft__item").mouseleave(function () {
      var cover = $(this).find(".nft__item_extra");
      cover.css("visibility", "hidden");
      cover.css("opacity", "0");
      $(this).find(".nft__item_click").data("iteration", 1);
    });

    // $(".nft__item_like").on("click", function () {
    //   var iteration = $(this).data("iteration") || 1;

    //   switch (iteration) {
    //     case 1:
    //       $(this).find("i").addClass("active");
    //       var val = parseInt($(this).find("span").text()) + 1;
    //       $(this).find("span").text(val);
    //       break;
    //     case 2:
    //       $(this).find("i").removeClass("active");
    //       var val = parseInt($(this).find("span").text()) - 1;
    //       $(this).find("span").text(val);
    //       break;
    //   }
    //   iteration++;
    //   if (iteration > 2) iteration = 1;
    //   $(this).data("iteration", iteration);
    // });
  });

  return (
    <>
      <Header />
      {/* <!-- content begin --> */}
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <section
          id="section-hero"
          className="no-bottom p-0"
          aria-label="section"
        >
          <div className="">
            <div id="" className="owl-item fadeIn">
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={3}
                navigation
                // scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
              >
                {HomeMainData.map((slideImage, index) => {
                  return (
                    <>
                      <SwiperSlide>
                        <div className="nft_pic " key={index}>
                          <Link
                            to={`/item-details/${slideImage?.imageUpload_id}`}
                          >
                            <span className="nft_pic_info img-info">
                              <span className="nft_pic_title">
                                {slideImage?.NameofPainting}
                              </span>
                              <span className="nft_pic_by">
                                {slideImage.imgby}
                              </span>
                            </span>
                          </Link>
                          <div className="nft_pic_wrap">
                            <img
                              src={slideImage?.fileURL}
                              className="homeimgSty img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>

        <section id="section-collections" className="pt30 pb30">
          <div className="container">
            <div className="spacer-single"></div>

            <div className="row  fadeIn">
              <div className="col-lg-12">
                <h2 className="style-2">New Items</h2>
              </div>

              {/* <!-- nft item begin --> */}
              <Card />
            </div>

            <div className="spacer-single"></div>

            <div className="row">
              <div className="col-lg-12">
                <h2 className="style-2">Hot Collections</h2>
              </div>
              <div
                id="collection-carousel-alt"
                className="owl-carousel  fadeIn"
              >
                <Slide
                  slidesToShow={2}
                  responsive={responsiveSettings}
                  autoplay={false}
                >
                  {CollectionData.map((colImage, index) => (
                    <div className="nft_coll owl-item style-2">
                      <div className="nft_wrap">
                        <Link to="#" className="LinkText">
                          <img
                            src={colImage.img}
                            className="lazy img-fluid"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="nft_coll_pp">
                        <Link to="#" className="LinkText">
                          <img
                            className="lazy pp-coll"
                            src={colImage.authorimg}
                            alt=""
                          />
                        </Link>
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </div>
                      <div className="nft_coll_info">
                        <Link to="#" className="LinkText">
                          <h4>{colImage.title}</h4>
                        </Link>
                        <span>{colImage.erc}</span>
                      </div>
                    </div>
                  ))}
                </Slide>
                {/* <div className="nft_coll owl-item style-2">
                  <div className="nft_wrap">
                    <Link to="#" className="LinkText">
                      <img src={coll2} className="lazy img-fluid" alt="" />
                    </Link>
                  </div>
                  <div className="nft_coll_pp">
                    <Link to="#" className="LinkText">
                      <img className="lazy pp-coll" src={author1} alt="" />
                    </Link>
                    <i className="fa fa-check">
                      <FaCheck />
                    </i>
                  </div>
                  <div className="nft_coll_info">
                    <Link to="#" className="LinkText">
                      <h4>Patternlicious</h4>
                    </Link>
                    <span>ERC-61</span>
                  </div>
                </div>

                <div className="nft_coll owl-item style-2">
                  <div className="nft_wrap">
                    <Link to="#" className="LinkText">
                      <img src={coll3} className="lazy img-fluid" alt="" />
                    </Link>
                  </div>
                  <div className="nft_coll_pp">
                    <Link to="#" className="LinkText">
                      <img className="lazy pp-coll" src={author1} alt="" />
                    </Link>
                    <i className="fa fa-check">
                      <FaCheck />
                    </i>
                  </div>
                  <div className="nft_coll_info">
                    <Link to="#" className="LinkText">
                      <h4>Skecthify</h4>
                    </Link>
                    <span>ERC-126</span>
                  </div>
                </div>

                <div className="nft_coll owl-item style-2">
                  <div className="nft_wrap">
                    <Link to="#" className="LinkText">
                      <img src={coll4} className="lazy img-fluid" alt="" />
                    </Link>
                  </div>
                  <div className="nft_coll_pp">
                    <Link to="#" className="LinkText">
                      <img className="lazy pp-coll" src={author1} alt="" />
                    </Link>
                    <i className="fa fa-check">
                      <FaCheck />
                    </i>
                  </div>
                  <div className="nft_coll_info">
                    <Link to="#" className="LinkText">
                      <h4>Cartoonism</h4>
                    </Link>
                    <span>ERC-73</span>
                  </div>
                </div>

                <div className="nft_coll owl-item style-2">
                  <div className="nft_wrap">
                    <Link to="#" className="LinkText">
                      <img src={coll5} className="lazy img-fluid" alt="" />
                    </Link>
                  </div>
                  <div className="nft_coll_pp">
                    <Link to="#" className="LinkText">
                      <img className="lazy pp-coll" src={author1} alt="" />
                    </Link>
                    <i className="fa fa-check">
                      <FaCheck />
                    </i>
                  </div>
                  <div className="nft_coll_info">
                    <Link to="#" className="LinkText">
                      <h4>Virtuland</h4>
                    </Link>
                    <span>ERC-85</span>
                  </div>
                </div>

                <div className="nft_coll owl-item style-2">
                  <div className="nft_wrap">
                    <Link to="#" className="LinkText">
                      <img src={coll6} className="lazy img-fluid" alt="" />
                    </Link>
                  </div>
                  <div className="nft_coll_pp">
                    <Link to="#" className="LinkText">
                      <img className="lazy pp-coll" src={author1} alt="" />
                    </Link>
                    <i className="fa fa-check">
                      <FaCheck />
                    </i>
                  </div>
                  <div className="nft_coll_info">
                    <Link to="#" className="LinkText">
                      <h4>Papercut</h4>
                    </Link>
                    <span>ERC-42</span>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="spacer-double"></div>

            <div className="row">
              <div className="col-lg-12">
                <h2 className="style-2">Top Sellers</h2>
              </div>
              <div className="col-md-12  fadeIn">
                <ol className="author_list">
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link to="/creator-profile " className="LinkText">
                        Monica Lucas
                      </Link>
                      <span>3.2 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link className="LinkText" to="/profile">
                        Mamie Barnett
                      </Link>
                      <span>2.8 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link className="LinkText" to="/profile">
                        Nicholas Daniels
                      </Link>
                      <span>2.5 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link className="LinkText" to="/profile">
                        Lori Hart
                      </Link>
                      <span>2.2 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link className="LinkText" to="/profile">
                        Jimmy Wright
                      </Link>
                      <span>1.9 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link className="LinkText" to="/profile">
                        Karla Sharp
                      </Link>
                      <span>1.6 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link className="LinkText" to="/profile">
                        Gayle Hicks
                      </Link>
                      <span>1.5 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link className="LinkText" to="/profile">
                        Claude Banks
                      </Link>
                      <span>1.3 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link className="LinkText" to="/profile">
                        Franklin Greer
                      </Link>
                      <span>0.9 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link className="LinkText" to="/profile">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link to="/profile" className="LinkText">
                        Stacy Long
                      </Link>
                      <span>0.8 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link to="/profile" className="LinkText">
                        Ida Chapman
                      </Link>
                      <span>0.6 ETH</span>
                    </div>
                  </li>
                  <li>
                    <div className="author_list_pp">
                      <Link to="/creator-profile " className="LinkText">
                        <img className="lazy" src={author1} alt="" />
                        <i className="fa fa-check">
                          <FaCheck />
                        </i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link to="/creator-profile" className="LinkText">
                        Fred Ryan
                      </Link>
                      <span>0.5 eth</span>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section id="section-text" className="no-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="style-2">Create and Sell Now</h2>
              </div>

              <div className="col-lg-4 col-md-6 mb-sm-30">
                <div className="feature-box f-boxed style-3">
                  <i className=" fadeInUp bg-color-2 i-boxed icon_wallet">
                    <BsWallet2 />
                  </i>

                  <div className="text">
                    <h4 className=" fadeInUp">Set up your wallet</h4>
                    <p className=" fadeInUp" data-wow-delay=".25s">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem.
                    </p>
                  </div>
                  <i className="wm icon_wallet"></i>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-sm-30">
                <div className="feature-box f-boxed style-3">
                  <i className=" fadeInUp bg-color-2 i-boxed icon_cloud-upload_alt">
                    <MdOutlineCloudUpload />
                  </i>
                  <div className="text">
                    <h4 className=" fadeInUp">Add your NFT's</h4>
                    <p className=" fadeInUp" data-wow-delay=".25s">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem.
                    </p>
                  </div>
                  <i className="wm icon_cloud-upload_alt"></i>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-sm-30">
                <div className="feature-box f-boxed style-3">
                  <i className=" fadeInUp bg-color-2 i-boxed icon_tags_alt">
                    <AiOutlineTags />
                  </i>
                  <div className="text">
                    <h4 className=" fadeInUp">Sell your NFT's</h4>
                    <p className=" fadeInUp" data-wow-delay=".25s">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem.
                    </p>
                  </div>
                  <i className="wm icon_tags_alt"></i>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- content close --> */}
      <Footer />
    </>
  );
}

export default HomePage;
