import React, { useEffect, useState } from "react";
import "../Creator/CreatorProfile.css";
import Header from "../Header/Header";
import profileImg from "../../Assets/images/author/author-1.jpg";
import static1 from "../../Assets/images/items-alt/static-1.jpg";
import {
  FaCheck,
  FaEnvelope,
  FaFacebookF,
  FaHeart,
  FaRegHeart,
  FaTwitter,
} from "react-icons/fa";
import jQuery from "jquery";
import $ from "jquery";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import { API_DOMAIN } from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { AppBar, Tab, Tabs } from "@mui/material";
function copyText(element) {
  var $copyText = jQuery(element).text();
  var button = jQuery("#btn_copy_address");
  navigator.clipboard.writeText($copyText).then(
    function () {
      var originalText = button.text();
      button.html("Copied!");
      button.addClass("clicked");
      setTimeout(function () {
        button.html(originalText);
        button.removeClass("clicked");
      }, 750);
    },
    function () {
      button.html("Error");
    }
  );
}
function CreatorProfile() {
  const navigate = useNavigate();
  const { userid } = useParams();
  const [profileData, setProfileData] = useState([]);
  const [LikeCountData, setLikeCountData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setSelectedTab(newValue);
  };
  useEffect(() => {
    $(".de_tab").find(".de_tab_content > div").hide();
    $(".de_tab").find(".de_tab_content > div:first").show();
    $("li").find(".v-border").fadeTo(150, 0);
    $("li.active").find(".v-border").fadeTo(150, 1);
    $(".de_nav li").on("click", function () {
      $(this).parent().find("li").removeClass("active");
      $(this).addClass("active");
      $(this).parent().parent().find(".v-border").fadeTo(150, 0);
      $(this).parent().parent().find(".de_tab_content > div").hide();
      var indexer = $(this).index(); //gets the current index of (this) which is #nav li
      $(this)
        .parent()
        .parent()
        .find(".de_tab_content > div:eq(" + indexer + ")")
        .fadeIn(); //uses whatever index the link has to open the corresponding box
      $(this).find(".v-border").fadeTo(150, 1);
    });

    $(".nft__item_click").on("click", function () {
      var iteration = $(this).data("iteration") || 1;

      switch (iteration) {
        case 1:
          var cover = $(this).parent().parent().find(".nft__item_extra");
          cover.css("visibility", "visible");
          cover.css("opacity", "1");
          break;
        case 2:
          var cover = $(this).parent().parent().find(".nft__item_extra");
          cover.css("visibility", "hidden");
          cover.css("opacity", "0");
          break;
      }
      iteration++;
      if (iteration > 2) iteration = 1;
      $(this).data("iteration", iteration);
    });

    $(".nft__item").mouseleave(function () {
      var cover = $(this).find(".nft__item_extra");
      cover.css("visibility", "hidden");
      cover.css("opacity", "0");
      $(this).find(".nft__item_click").data("iteration", 1);
    });
  });
  const getProfileData = () => {
    var API_URL = API_DOMAIN + `profile/get?userid=${userid}`;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("Profile details ", response.data.data);
        var res = [response.data.data];
        setProfileData(res);
        // setFileURL(res.ProfileURL);
        // setFirstName(res.firstname);
        // setLastName(res.lastname);
        // setCustomURL(res.customURL);
        // setBio(res.Bio);
        // setEmail(res.email);
        // setMobileNumber(res.mobile);
        // setYoursite(res.Website);
        // setTwitterusername(res.Twitter);
        // setInstausername(res.Instagram);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const getLikeCount = () => {
    var API_URL = API_DOMAIN + `like/byuserid?userid=${userid}`;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("Like Count Data ", response.data.data);
        var res = response.data.data;
        // handleLikeShow(res);

        setLikeCountData(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const handleLikeShow = (res) => {
    for (let i = 0; i < res.length; i++) {
      const arr = [];
      const element = res[i].LikeData;
      console.log(element);
      for (let j = 0; j < element.length; j++) {
        if (element[j].userid === sessionStorage.getItem("userid")) {
          console.log("match", res[i]);
          res[i].isLike = true;
          console.log((res[i].isLike = true));
        }
      }
    }
    console.log(res);
    setLikeCountData(res);
  };
  const handleLikeShowCreated = (res) => {
    for (let i = 0; i < res.length; i++) {
      const arr = [];
      const element = res[i].LikeData;
      console.log(element);
      for (let j = 0; j < element.length; j++) {
        if (element[j].userid === sessionStorage.getItem("userid")) {
          console.log("match", res[i]);
          res[i].isLike = true;
          console.log((res[i].isLike = true));
        }
      }
    }
    console.log(res);
    setMyCreatedData(res);
  };
  const handleLikeShowBought = (res) => {
    for (let i = 0; i < res.length; i++) {
      const arr = [];
      const element = res[i].LikeData;
      console.log(element);
      for (let j = 0; j < element.length; j++) {
        if (element[j].userid === sessionStorage.getItem("userid")) {
          console.log("match", res[i]);
          res[i].isLike = true;
          console.log((res[i].isLike = true));
        }
      }
    }
    console.log(res);
    setMyBoughtData(res);
  };
  const FollowHandle = () => {
    if (sessionStorage.getItem("userid") !== userid) {
      toast.info("Please login first to Follow/Unfollow");
    } else {
      var API_URL = API_DOMAIN + "like/follow/addupdate";
      let headerConfig = {
        headers: {
          accept: "application/json",
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        followedBy: sessionStorage.getItem("userid"),
        userid: userid,
        isFollow: true,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("Follow Successfully", response);
          geFollowCount();
          getProfileData();
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {});
    }
  };
  const unFollowHandle = () => {
    if (sessionStorage.getItem("userid") !== userid) {
      toast.info("Please login first to Follow/Unfollow");
    } else {
      var API_URL = API_DOMAIN + "like/follow/addupdate";
      let headerConfig = {
        headers: {
          accept: "application/json",
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        followedBy: sessionStorage.getItem("userid"),
        userid: userid,
        isFollow: false,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("unFollow Successfully", response);
          setShowFollow(true);
          geFollowCount();
          getProfileData();
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {});
    }
  };
  const [followCount, setFollowCount] = useState("");
  const [showFollow, setShowFollow] = useState(true);
  const geFollowCount = () => {
    var API_URL = API_DOMAIN + `like/follow/count?userid=${userid}`;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("get follow count", response.data.data);
        var res = response.data.data;
        var followDetails = res.followDetails;
        for (let i = 0; i < followDetails.length; i++) {
          if (
            followDetails[i].followedBy === sessionStorage.getItem("userid")
          ) {
            setShowFollow(false);
          }
        }
        setFollowCount(res.followCount);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const [myBoughtData, setMyBoughtData] = useState([]);
  const mybought = () => {
    var API_URL = API_DOMAIN + `mycontract/get?userid=${userid}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("bought", response.data.data);
        // setMyBoughtData(response.data.data);
        handleLikeShowBought(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const [myCreatedData, setMyCreatedData] = useState([]);
  const myCreated = () => {
    var API_URL = API_DOMAIN + `image/select/userid?userid=${userid}`;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("created", response.data.data);
        // setMyCreatedData(response.data.data);
        handleLikeShowCreated(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    geFollowCount();

    getProfileData();
    getLikeCount();
    if (sessionStorage.getItem("token") !== null) {
      mybought();
    }
    myCreated();
  }, [userid]);
  const LikeRemoveCountHandle = (imgid) => {
    if (sessionStorage.getItem("userid") !== userid) {
      toast.info("Please login first to Like/Dislike");
    } else {
      var API_URL = API_DOMAIN + "like/addupdate";
      let headerConfig = {
        headers: {
          accept: "application/json",
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        imageUpload_id: imgid,
        userid: sessionStorage.getItem("userid"),
        isLike: false,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("click Like Remove btn", response.data.data);
          if (response.status === 200) {
            getLikeCount();
            mybought();
            myCreated();
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            toast.info("Please Login First");
          }
        })
        .then(function () {});
    }
  };
  const LikeAddCountHandle = (imgid) => {
    if (sessionStorage.getItem("userid") !== userid) {
      toast.info("Please login first to Like/Dislike");
    } else {
      var API_URL = API_DOMAIN + "like/addupdate";
      let headerConfig = {
        headers: {
          accept: "application/json",
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        imageUpload_id: imgid,
        userid: sessionStorage.getItem("userid"),
        isLike: true,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("click Like btn", response.data.data);
          if (response.status === 200) {
            getLikeCount();
            mybought();
            myCreated();
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            toast.info("Please Login First");
          }
        })
        .then(function () {});
    }
  };
  return (
    <>
      <Header />

      <div className="no-bottom pt-4" id="content">
        <div id="top"></div>

        <section aria-label="section" className="pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d_profile de-flex">
                  <div className="de-flex-col">
                    {profileData.map((v) => {
                      return (
                        <>
                          <div className="profile_avatar">
                            <img
                              src={
                                v.ProfileURL === "" ? profileImg : v.ProfileURL
                              }
                              alt=""
                            />
                            <i className="fa fa-check">
                              <FaCheck />
                            </i>
                            <div className="profile_name">
                              <h4>
                                {v.firstname} {v.lastname}
                                <span className="profile_username">
                                  {/* @monicaaa */}
                                </span>
                                {v.walletAddress === undefined ? (
                                  <></>
                                ) : (
                                  <>
                                    <span
                                      id="walletaddress"
                                      className="profile_wallet"
                                    >
                                      {v.walletAddress}
                                    </span>
                                    <button
                                      id="btn_copy_address"
                                      title="Copy Text"
                                      onClick={() => copyText("#walletaddress")}
                                    >
                                      Copy
                                    </button>
                                  </>
                                )}
                              </h4>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="profile_follow de-flex">
                    <div className="de-flex-col">
                      <div className="profile_follower">
                        {followCount} followers
                      </div>
                    </div>
                    <div className="de-flex-col">
                      {showFollow === false ? (
                        <>
                          <button className="btn-main" onClick={unFollowHandle}>
                            Unfollow
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="btn-main" onClick={FollowHandle}>
                            Follow
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="de_tab tab_simple">
                  {/* <ul className="de_nav">
                    <li className="active">
                      <span>Liked</span>
                    </li>

                    <li>
                      <span>Created</span>
                    </li>
                    {sessionStorage.getItem("userid") === userid ? (
                      <>
                        <li>
                          <span>My Bought</span>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul> */}
                  <AppBar
                    position="static"
                    className=" TabStyle "
                    id="mobBoxtab"
                  >
                    <Tabs value={selectedTab} onChange={handleChange}>
                      <Tab
                        label="Liked"
                        className={
                          selectedTab === 0 ? " selectedtabColor" : "tabColor "
                        }
                      />
                      <Tab
                        label="Created"
                        className={
                          selectedTab === 1 ? " selectedtabColor" : "tabColor "
                        }
                      />
                      {sessionStorage.getItem("userid") === userid && (
                        <Tab
                          label="My Bought"
                          className={
                            selectedTab === 2
                              ? " selectedtabColor"
                              : "tabColor "
                          }
                        />
                      )}
                    </Tabs>
                  </AppBar>
                  {selectedTab === 0 && (
                    <>
                      <div className="row mt-5 fadeIn">
                        {LikeCountData.map((v) => {
                          return (
                            <>
                              <div className="col-lg-3 col-md-6">
                                <div className="nft__item">
                                  <div className="author_list_pp">
                                    <Link
                                      to={`/creator-profile/${v?.UserDetails[0].userid}`}
                                    >
                                      <img
                                        className="lazy"
                                        src={
                                          v.UserDetails[0]?.ProfileURL === "" ||
                                          v.UserDetails[0]?.ProfileURL ===
                                            undefined
                                            ? profileImg
                                            : v.UserDetails[0]?.ProfileURL
                                        }
                                        alt=""
                                      />
                                      <i className="fa fa-check">
                                        <FaCheck />
                                      </i>
                                    </Link>
                                  </div>
                                  <div className="nft__item_wrap">
                                    <div className="nft__item_extra">
                                      <div className="nft__item_buttons">
                                        <button onClick={() => navigate("#")}>
                                          Buy Now
                                        </button>
                                        <div className="nft__item_share">
                                          <h4>Share</h4>
                                          <Link target="_blank">
                                            <i className="fa fa-facebook fa-lg">
                                              <FaFacebookF />
                                            </i>
                                          </Link>
                                          <Link target="_blank">
                                            <i className="fa fa-twitter fa-lg">
                                              <FaTwitter />
                                            </i>
                                          </Link>
                                          <Link target="_blank">
                                            <i className="fa fa-envelope fa-lg">
                                              <FaEnvelope />
                                            </i>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <Link
                                      to={`/item-details/${v.imageData.imageUpload_id}`}
                                    >
                                      <img
                                        src={
                                          v.imageData.fileURL === ""
                                            ? static1
                                            : v.imageData.fileURL
                                        }
                                        className="lazy nft__item_preview cardimgstyle"
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                  <div className="nft__item_info">
                                    <Link
                                      to={`/item-details/${v.imageUpload_id}`}
                                      className="LinkText"
                                    >
                                      <h4>{v.imageData.NameofPainting}</h4>
                                    </Link>
                                    <div className="nft__item_click">
                                      <span></span>
                                    </div>
                                    <div className="nft__item_price">
                                      {v.imageData.price} ETH
                                      <span>1/20</span>
                                    </div>
                                    <div className="nft__item_action">
                                      <Link
                                        to={`/item-details/${v.imageUpload_id}`}
                                        className="LinkText"
                                      >
                                        Place a bid
                                      </Link>
                                    </div>
                                    <div className="nft__item_like">
                                      {v?.isLike === true ? (
                                        <>
                                          {" "}
                                          <FaHeart
                                            style={{ color: "#dd4848" }}
                                            onClick={() => [
                                              LikeRemoveCountHandle(
                                                v.imageUpload_id
                                              ),
                                            ]}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <FaRegHeart
                                            style={{ color: "#dd4848" }}
                                            onClick={() => [
                                              LikeAddCountHandle(
                                                v.imageUpload_id
                                              ),
                                            ]}
                                          />
                                        </>
                                      )}
                                      <span>{v.LikeCount}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}

                  {selectedTab === 1 && (
                    <>
                      <div className="row mt-5 ">
                        {myCreatedData.map((v) => {
                          return (
                            <>
                              {/* <!-- nft item begin --> */}
                              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="nft__item">
                                  <div className="author_list_pp">
                                    <Link
                                      to={`/creator-profile/${v?.UserDetails[0].userid}`}
                                    >
                                      <img
                                        className="lazy"
                                        src={
                                          v.UserDetails[0]?.ProfileURL === "" ||
                                          v.UserDetails[0]?.ProfileURL ===
                                            undefined
                                            ? profileImg
                                            : v.UserDetails[0]?.ProfileURL
                                        }
                                        alt=""
                                      />
                                      <i className="fa fa-check">
                                        <FaCheck />
                                      </i>
                                    </Link>
                                  </div>
                                  <div className="nft__item_wrap">
                                    <div className="nft__item_extra">
                                      <div className="nft__item_buttons">
                                        <button
                                          onClick={() =>
                                            navigate("/create-digital-copy", {
                                              state: {
                                                contractaddress:
                                                  v.contractaddress,
                                                imageID: v.imageUpload_id,
                                              },
                                            })
                                          }
                                        >
                                          Create Digital Copy
                                        </button>
                                        <div className="nft__item_share">
                                          <h4>Share</h4>
                                          <Link target="_blank">
                                            <i className="fa fa-facebook fa-lg">
                                              {" "}
                                              <FaFacebookF />
                                            </i>
                                          </Link>
                                          <Link target="_blank">
                                            <i className="fa fa-twitter fa-lg">
                                              {" "}
                                              <FaTwitter />
                                            </i>
                                          </Link>
                                          <Link>
                                            <i className="fa fa-envelope fa-lg">
                                              <FaEnvelope />
                                            </i>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <Link to="#">
                                      <img
                                        src={
                                          v.fileURL === "" ? static1 : v.fileURL
                                        }
                                        className="lazy cardimgstyle nft__item_preview"
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                  <div className="nft__item_info">
                                    <Link
                                      to={`/item-details/${v.imageUpload_id}`}
                                      className="LinkText"
                                    >
                                      <h4>{v?.NameofPainting}</h4>
                                    </Link>
                                    <div className="nft__item_click">
                                      <span></span>
                                    </div>
                                    <div className="nft__item_price">
                                      {v.price} ETH
                                      <span>1/11</span>
                                    </div>
                                    <div className="nft__item_action">
                                      <Link
                                        to={`/item-details/${v.imageUpload_id}`}
                                        className="LinkText"
                                      >
                                        Place a bid
                                      </Link>
                                    </div>
                                    <div className="nft__item_like">
                                      {v?.isLike === true ? (
                                        <>
                                          {" "}
                                          <FaHeart
                                            style={{ color: "#dd4848" }}
                                            onClick={() => [
                                              LikeRemoveCountHandle(
                                                v.imageUpload_id
                                              ),
                                            ]}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <FaRegHeart
                                            style={{ color: "#dd4848" }}
                                            onClick={() => [
                                              LikeAddCountHandle(
                                                v.imageUpload_id
                                              ),
                                            ]}
                                          />
                                        </>
                                      )}
                                      <span>{v.LikeData.length}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                  {selectedTab === 2 && (
                    <>
                      <div className="row mt-5">
                        {myBoughtData.map((v) => {
                          return (
                            <>
                              {/* <!-- nft item begin --> */}
                              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="nft__item">
                                  <div className="author_list_pp">
                                    <Link
                                      to={`/creator-profile/${v?.UserDetails[0].userid}`}
                                    >
                                      <img
                                        className="lazy"
                                        src={
                                          v.UserDetails[0]?.ProfileURL === "" ||
                                          v.UserDetails[0]?.ProfileURL ===
                                            undefined
                                            ? profileImg
                                            : v.UserDetails[0]?.ProfileURL
                                        }
                                        alt=""
                                      />
                                      <i className="fa fa-check">
                                        <FaCheck />
                                      </i>
                                    </Link>
                                  </div>
                                  <div className="nft__item_wrap">
                                    <div className="nft__item_extra">
                                      <div className="nft__item_buttons">
                                        <button onClick={() => navigate("#")}>
                                          Buy Now
                                        </button>
                                        <div className="nft__item_share">
                                          <h4>Share</h4>
                                          <Link target="_blank">
                                            <i className="fa fa-facebook fa-lg">
                                              <FaFacebookF />
                                            </i>
                                          </Link>
                                          <Link target="_blank">
                                            <i className="fa fa-twitter fa-lg">
                                              <FaTwitter />
                                            </i>
                                          </Link>
                                          <Link target="_blank">
                                            <i className="fa fa-envelope fa-lg">
                                              <FaEnvelope />
                                            </i>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <Link to="#">
                                      <img
                                        src={
                                          v.imageData.fileURL === ""
                                            ? static1
                                            : v.imageData.fileURL
                                        }
                                        className="lazy cardimgstyle nft__item_preview"
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                  <div className="nft__item_info">
                                    <Link
                                      to={`/item-details/${v.imageData.imageUpload_id}`}
                                      className="LinkText"
                                    >
                                      <h4>{v.imageData.NameofPainting}</h4>
                                    </Link>
                                    <div className="nft__item_click">
                                      <span></span>
                                    </div>
                                    <div className="nft__item_price">
                                      {v.imageData.price} ETH
                                      <span>1/20</span>
                                    </div>
                                    <div className="nft__item_action">
                                      <Link
                                        to={`/item-details/${v.imageData.imageUpload_id}`}
                                        className="LinkText"
                                      >
                                        Place a bid
                                      </Link>
                                    </div>
                                    <div className="nft__item_like">
                                      {v?.isLike === true ? (
                                        <>
                                          {" "}
                                          <FaHeart
                                            style={{ color: "#dd4848" }}
                                            onClick={() => [
                                              LikeRemoveCountHandle(
                                                v.imageData.imageUpload_id
                                              ),
                                            ]}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <FaRegHeart
                                            style={{ color: "#dd4848" }}
                                            onClick={() => [
                                              LikeAddCountHandle(
                                                v.imageData.imageUpload_id
                                              ),
                                            ]}
                                          />
                                        </>
                                      )}
                                      <span>{v.LikeData.length}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default CreatorProfile;
