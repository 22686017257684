import React, { useEffect, useState } from "react";
import "../Profile/Profile.css";
import Header from "../Header/Header";
import { IoPerson } from "react-icons/io5";
import {
  FaCamera,
  FaCircleExclamation,
  FaCircleInfo,
  FaPaintbrush,
} from "react-icons/fa6";
import $ from "jquery";
import Footer from "../Footer/Footer";
import author from "../../Assets/images/author/author-1.jpg";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import { API_DOMAIN } from "../../utils/GlobalConst";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3 from "web3";
function Profile() {
  const navigate = useNavigate();
  const userid = sessionStorage.getItem("userid");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [profileImg, setProfileImg] = useState([]);
  const [fileURL, setFileURL] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [customURL, setCustomURL] = useState("");
  const [bio, setBio] = useState("");
  const [yoursite, setYoursite] = useState("");
  const [twitterusername, setTwitterusername] = useState("");
  const [instausername, setInstausername] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  const [openUploadimage, setOpenUploadimage] = useState(false);

  const handleProfileImgUpload = () => {
    // console.log(img)
    var API_URL = API_DOMAIN + "image/upload";
    var formData = new FormData();
    formData.append("att_file", profileImg[0]);
    console.log(formData);

    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, formData, headerConfig)
      .then(function (response) {
        console.log("Profile Img Upload", response);
        setFileURL(response.data.data.filePath);
        console.log(response.data.data.filePath);
        if (response.status === 200) {
          setOpenUploadimage(false);
          UpdateImageData(response.data.data.filePath);
          // handleUpdateProfileurl(response?.data?.data?.Location);
          // handleCloseProfileImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          // toast.error(error.response.data.message);
          setTimeout(() => {
            // setLoading(false);
            // handleCloseProfileImagePopup();
            setProfileImg("");
          });
        }
      })
      .then(function () {});
  };

  useEffect(() => {
    $(".de_tab").find(".de_tab_content > div").hide();
    $(".de_tab").find(".de_tab_content > div:first").show();
    $("li").find(".v-border").fadeTo(150, 0);
    $("li.active").find(".v-border").fadeTo(150, 1);
    $(".de_nav li").on("click", function () {
      $(this).parent().find("li").removeClass("active");
      $(this).addClass("active");
      $(this).parent().parent().find(".v-border").fadeTo(150, 0);
      $(this).parent().parent().find(".de_tab_content > div").hide();
      var indexer = $(this).index(); //gets the current index of (this) which is #nav li
      $(this)
        .parent()
        .parent()
        .find(".de_tab_content > div:eq(" + indexer + ")")
        .fadeIn(); //uses whatever index the link has to open the corresponding box
      $(this).find(".v-border").fadeTo(150, 1);
    });
  });
  const getProfileData = () => {
    var API_URL = API_DOMAIN + `profile/get?userid=${userid}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL,headerConfig)
      .then((response) => {
        console.log("Profile details ", response.data.data);
        var res = response.data.data;
        setFileURL(res.ProfileURL);
        setFirstName(res.firstname);
        setLastName(res.lastname);
        setCustomURL(res.customURL);
        setBio(res.Bio);
        setEmail(res.email);
        setMobileNumber(res.mobile);
        setYoursite(res.Website);
        setTwitterusername(res.Twitter);
        setInstausername(res.Instagram);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const handleProfileData = () => {
    var API_URL = API_DOMAIN + "profile/edit";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      userid: userid,
      Bio: bio,
      Website: yoursite,
      Twitter: twitterusername,
      Instagram: instausername,
      mobile: mobileNumber,
      customURL: customURL,
      ProfileURL: fileURL,
      firstname: firstName,
      lastname: lastName,
      walletAddress: walletAddress,
    };
    axios
      .patch(API_URL, data,headerConfig)
      .then((response) => {
        console.log("Profile data add ", response.data.data);
        if (response.status === 200) {
          toast.info("Profile updated successfully")
          sessionStorage.setItem("userfirstname", response.data.data.firstname);
          sessionStorage.setItem("userlastname", response.data.data.lastname);
          getProfileData()
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const UpdateImageData = (url) => {
    var API_URL = API_DOMAIN + "profile/image/edit";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      ProfileURL: url,
      userid: userid,
    };
    axios
      .patch(API_URL, data,headerConfig)
      .then((response) => {
        console.log("Image Upadate ", response.data.data);
        if (response.status === 200) {
          getProfileData();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    getProfileData();
    connectMetamask();
  }, []);
  async function connectMetamask() {
    //check metamask is installed
    if (window.ethereum) {
      // instantiate Web3 with the injected provider
      const web3 = new Web3(window.ethereum);

      //request user to connect accounts (Metamask will prompt)
      await window.ethereum.request({ method: "eth_requestAccounts" });

      //get the connected accounts
      const accounts = await web3.eth.getAccounts();
      //show the first connected account in the react page
      sessionStorage.setItem("metamaskAccountAddress", accounts[0]);
      setWalletAddress(accounts[0]);
    } else {
      alert("Please download metamask");
    }
  }
  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        {/* <!-- section begin --> */}
        <section id="subheader" className="pt-0">
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Edit Profile</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section close --> */}

        {/* <!-- section begin --> */}
        <section id="section-main" aria-label="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div id="form-create-item" className="form-border">
                  <div className="de_tab tab_simple">
                    <ul className="de_nav">
                      <li className="active">
                        <span>
                          <i className="fa fa-user">
                            <IoPerson />
                          </i>
                          Profile
                        </span>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-exclamation-circle">
                            <FaCircleExclamation />
                          </i>
                          Notifications
                        </span>
                      </li>
                    </ul>

                    <div className="de_tab_content">
                      <div className="tab-1">
                        <div className="row  fadeIn">
                          <div className="col-lg-8 mb-sm-20">
                            <div className="field-set">
                              <h5>First Name</h5>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />

                              <div className="spacer-20"></div>
                              <h5>Last Name</h5>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />

                              <div className="spacer-20"></div>
                              <h5>Wallet Address</h5>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your wallet address"
                                value={walletAddress}
                                onChange={(e) =>
                                  setWalletAddress(e.target.value)
                                }
                              />
                              <div className="spacer-20"></div>
                              <h5>Custom URL</h5>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your custom URL"
                                value={customURL}
                                onChange={(e) => setCustomURL(e.target.value)}
                              />

                              <div className="spacer-20"></div>

                              <h5>Bio</h5>
                              <textarea
                                className="form-control"
                                placeholder="Tell the world who you are!"
                                value={bio}
                                onChange={(e) => setBio(e.target.value)}
                              ></textarea>

                              <div className="spacer-5"></div>

                              <h5>Email Address</h5>
                              <input
                                type="text"
                                name="email_address"
                                id="email_address"
                                className="form-control disableSty"
                                placeholder="Enter Email Address"
                                value={email}
                                disabled
                                onChange={(e) => setEmail(e.target.value)}
                              />

                              <div className="spacer-20"></div>
                              <h5>Mobile Number</h5>
                              <input
                                type="text"
                                className="form-control disableSty"
                                placeholder="Enter Mobile Number"
                                value={mobileNumber}
                                disabled
                                onChange={(e) =>
                                  setMobileNumber(e.target.value)
                                }
                              />

                              <div className="spacer-20"></div>

                              <h5>Your site</h5>
                              <input
                                type="text"
                                name="your_site"
                                id="your_site"
                                className="form-control"
                                placeholder="Enter Website URL"
                                value={yoursite}
                                onChange={(e) => setYoursite(e.target.value)}
                              />

                              <div className="spacer-20"></div>

                              <h5>Twitter username</h5>
                              <input
                                type="text"
                                name="twitter_usernam"
                                id="twitter_usernam"
                                className="form-control"
                                placeholder="Enter Twitter username"
                                value={twitterusername}
                                onChange={(e) =>
                                  setTwitterusername(e.target.value)
                                }
                              />

                              <div className="spacer-20"></div>

                              <h5>Instagram username</h5>
                              <input
                                type="text"
                                name="instagram_username"
                                id="instagram_username"
                                className="form-control"
                                placeholder="Enter Instagram username"
                                value={instausername}
                                onChange={(e) =>
                                  setInstausername(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div id="sidebar" className="col-lg-4">
                            <h5>
                              Profile image{" "}
                              <i
                                className="fa fa-info-circle id-color-2"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Recommend 400 x 400. Max size: 50MB."
                              >
                                <FaCircleInfo />
                              </i>
                            </h5>
                            <div className="user-avatar-img">
                              <img
                                src={fileURL === "" ? author : fileURL}
                                id="click_profile_img"
                                className="d-profile-img-edit img-fluid"
                                alt=""
                              />
                              <div
                                className="avatar-img-btn"
                                onClick={() => setOpenUploadimage(true)}
                              >
                                <input type="file" />
                                <label for="avatar-img">
                                  <FaCamera className="camera-icon" />
                                </label>
                              </div>
                            </div>
                            <div className="spacer-30"></div>

                            {/* <h5>
                              Profile banner{" "}
                              <i
                                className="fa fa-info-circle id-color-2"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."
                              >
                                <FaCircleInfo />
                              </i>
                            </h5>
                            <img
                              src={static1}
                              id="click_banner_img"
                              className="d-banner-img-edit img-fluid"
                              alt=""
                            />
                            <input type="file" id="" /> */}
                          </div>
                        </div>
                      </div>

                      <div className="tab-2">
                        <div className="row  fadeIn">
                          <div className="col-md-6 mb-sm-20">
                            <div className="switch-with-title s2">
                              <h5>Item Sold</h5>
                              <div className="de-switch">
                                <input
                                  type="checkbox"
                                  id="notif-item-sold"
                                  className="checkbox"
                                />
                                <label for="notif-item-sold"></label>
                              </div>
                              <div className="clearfix"></div>
                              <p className="p-info">
                                When someone purhased your item.
                              </p>
                            </div>

                            <div className="spacer-20"></div>

                            <div className="switch-with-title s2">
                              <h5>Bid Activity</h5>
                              <div className="de-switch">
                                <input
                                  type="checkbox"
                                  id="notif-bid-activity"
                                  className="checkbox"
                                />
                                <label for="notif-bid-activity"></label>
                              </div>
                              <div className="clearfix"></div>
                              <p className="p-info">
                                When someone purhased your item.
                              </p>
                            </div>

                            <div className="spacer-20"></div>

                            <div className="switch-with-title s2">
                              <h5>Price Change</h5>
                              <div className="de-switch">
                                <input
                                  type="checkbox"
                                  id="notif-price-change"
                                  className="checkbox"
                                />
                                <label for="notif-price-change"></label>
                              </div>
                              <div className="clearfix"></div>
                              <p className="p-info">
                                When an item you made an offer on changes in
                                price.
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="switch-with-title s2">
                              <h5>Auction Expiration</h5>
                              <div className="de-switch">
                                <input
                                  type="checkbox"
                                  id="notif-auction-expiration"
                                  className="checkbox"
                                />
                                <label for="notif-auction-expiration"></label>
                              </div>
                              <div className="clearfix"></div>
                              <p className="p-info">
                                When an auction you created ends.
                              </p>
                            </div>

                            <div className="spacer-20"></div>

                            <div className="switch-with-title s2">
                              <h5>Outbid</h5>
                              <div className="de-switch">
                                <input
                                  type="checkbox"
                                  id="notif-outbid"
                                  className="checkbox"
                                />
                                <label for="notif-outbid"></label>
                              </div>
                              <div className="clearfix"></div>
                              <p className="p-info">
                                When an offer you placed is exceeded by another
                                user.
                              </p>
                            </div>

                            <div className="spacer-20"></div>

                            <div className="switch-with-title s2">
                              <h5>Successful Purchase</h5>
                              <div className="de-switch">
                                <input
                                  type="checkbox"
                                  id="notif-successful-purchase"
                                  className="checkbox"
                                />
                                <label for="notif-successful-purchase"></label>
                              </div>
                              <div className="clearfix"></div>
                              <p className="p-info">
                                When you successfully buy an item.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="spacer-30"></div>
                  <input
                    type="button"
                    id="submit"
                    className="btn-main"
                    value="Update profile"
                    onClick={handleProfileData}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openUploadimage}
        onClose={() => setOpenUploadimage(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="text-center"
        ></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="d-flex justify-content-between mt-2">
              <h5>Upload Profile Image</h5>
              <RxCrossCircled
                className="cross-icon text-white"
                onClick={() => setOpenUploadimage(false)}
              />
            </div>

            <div className="d-create-file">
              <p>Recommend 400 x 400. Max size: 50MB.</p>
              <input
                type="file"
                className="text-white"
                onChange={(e) => [setProfileImg(e.target.files)]}
              />
            </div>
            <br />
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center gap-3 mb-3">
            <button
              className="btn-main cancel-btn"
              onClick={() => setOpenUploadimage(false)}
            >
              Cancel
            </button>
            <button className="btn-main" onClick={handleProfileImgUpload}>
              Save
            </button>
          </div>
        </>
      </Dialog>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default Profile;
