import React, { useEffect, useState } from "react";
import "../Header/Header.css";
import logo from "../../Assets/images/tntimages/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import author1 from "../../Assets/images/author/author-1.jpg";
import jQuery from "jquery";
import $ from "jquery";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import { TiPencil } from "react-icons/ti";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CgCloseO } from "react-icons/cg";
import axios from "axios";
import { toast } from "react-toastify";
import { API_DOMAIN } from "../../utils/GlobalConst";
import Web3 from "web3";

function copyText(element) {
  var $copyText = jQuery(element).text();
  var button = jQuery("#btn_copy");
  navigator.clipboard.writeText($copyText).then(
    function () {
      var originalText = button.text();
      button.html("Copied!");
      button.addClass("clicked");
      setTimeout(function () {
        button.html(originalText);
        button.removeClass("clicked");
      }, 750);
    },
    function () {
      button.html("Error");
    }
  );
}
function showInfo() {
  jQuery("#de-click-menu-profile").on("click", function () {
    var iteration = $(this).data("iteration") || 1;

    switch (iteration) {
      case 1:
        $("#de-submenu-profile").show();
        $("#de-submenu-profile").addClass("open");

        break;
      case 2:
        $("#de-submenu-profile").removeClass("open");
        $("#de-submenu-profile").hide();
        break;
    }
    iteration++;
    if (iteration > 2) iteration = 1;
    $(this).data("iteration", iteration);
  });
}
function name(params) {
  var mobile_menu_show = 0;

  var $tmp_h = "90";
  jQuery("#menu-btn").on("click", function () {
    $("#de-submenu-profile").removeClass("open");
    $("#de-submenu-profile").hide();
    $("#de-click-menu-profile").data("iteration", 1);
    $("#de-submenu-notification").removeClass("open");
    $("#de-submenu-notification").hide();
    $("#de-click-menu-notification").data("iteration", 1);

    var h = jQuery("header")[0].scrollHeight;

    if (mobile_menu_show == 0) {
      jQuery("header.header-mobile").stop(true).animate(
        {
          height: h,
        },
        200,
        "easeOutCubic"
      );
      mobile_menu_show = 1;
    } else {
      jQuery("header.header-mobile").stop(true).animate(
        {
          height: $tmp_h,
        },
        200,
        "easeOutCubic"
      );
      mobile_menu_show = 0;
    }
  });
}
function Header() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const userid = sessionStorage.getItem("userid");
  const [ProfileImage, setProfileImage] = useState([]);
  const istoken = sessionStorage.getItem("token");
  let explore,
    create,
    Home = "headerText";
  const pageName = window.location.pathname.split("/");
  if (pageName[1] === "") {
    Home = "active1";
    create = "headerText";
    explore = "headerText";
  } else if (pageName[1] === "create-token") {
    Home = "headerText";
    create = "active1";
    explore = "headerText";
  } else if (pageName[1] === "explore") {
    Home = "headerText";
    create = "headerText";
    explore = "active1";
  }
  const [opensignOut, setOpenSignout] = useState(false);

  const handleSignout = () => {
    navigate("/login");
    localStorage.clear();
    sessionStorage.clear();
  };
  const [walletAddress, setWalletAddress] = useState("");
  const [balance, setBalance] = useState("-");

  const getProfileData = () => {
    var API_URL = API_DOMAIN + `profile/get?userid=${userid}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("Profile Image ", response.data.data);
        var res = response.data.data;
        setProfileImage(res.ProfileURL);
        setWalletAddress(res.walletAddress);
        if (res.walletAddress) {
          getBalance(res.walletAddress).then((value) => {
            setBalance(value);
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      getProfileData();
    }
  }, []);
  async function getBalance(account) {
    const balance = await window.ethereum.request({
      method: "eth_getBalance",
      params: [account, "latest"],
    });
    return formatBalance(balance);
  }

  // Format to human-readable value
  function formatBalance(rawBalance) {
    return (parseInt(rawBalance) / 1000000000000000000).toFixed(2);
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className=" nav-wrapper">
            <div className="logo-container">
              <Link to="/">
                <img src={logo} alt="" className="brand-logo" />
              </Link>
            </div>

            <div className="">
              <nav>
                <input className="hidden" type="checkbox" id="menuToggle" />
                <label className="menu-btn" for="menuToggle">
                  <div className="menu "></div>
                  <div className="menu"></div>
                  <div className="menu"></div>
                </label>
                <div className="nav-container">
                  <ul className="nav-tabs">
                    <li className="nav-tab">
                      <Link to="/" className="nav-link" id={Home}>
                        Home
                      </Link>
                    </li>
                    <li className="nav-tab">
                      <Link to="/explore" className="nav-link" id={explore}>
                        Browse
                      </Link>
                    </li>
                    {istoken === "" ||
                    istoken === undefined ||
                    istoken === null ? (
                      <>
                        <li className="nav-tab">
                          {window.location.pathname === "/login" ? (
                            <>
                              <button
                                className="btn-main btn-wallet me-3 mt-1"
                                onClick={() => navigate("/register")}
                              >
                                <span>Register</span>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn-main btn-wallet me-3 mt-1"
                                onClick={() => navigate("/login")}
                              >
                                <span>Login</span>
                              </button>
                            </>
                          )}
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="nav-tab">
                          <Link
                            to="/create-token"
                            className="nav-link"
                            id={create}
                          >
                            Create
                          </Link>
                        </li>
                        <li className="nav-tab">
                          <button
                            className="btn-main btn-wallet mt-1"
                            onClick={() => navigate("/connect-wallet")}
                          >
                            <i className="icon_wallet_alt"></i>
                            <span>Connect TNT Wallet</span>
                          </button>
                        </li>
                        <li className="nav-tab">
                          <li className="nav-tab">
                            <Link
                              className="account-link"
                              role="button"
                              id="accountClick"
                              data-bs-auto-close="outside"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={
                                  ProfileImage === "" ? author1 : ProfileImage
                                }
                                alt=""
                              />
                              {/* <TiArrowSortedDown
                              className={active ? "text-muted" : "downarrow"}
                            /> */}
                            </Link>
                            <ul
                              className="dropdown-menu dropdown-menu-account dropdown-menu-end res-dropdown-header"
                              aria-labelledby="accountClick"
                            >
                              <li className="profile-link">
                                <div className="profileDetails">
                                  <div className="d-name">
                                    <h4>
                                      {sessionStorage.getItem("userfirstname")}{" "}
                                      {""}
                                      {sessionStorage.getItem("userlastname")}
                                    </h4>
                                    <Link to="/profile" className="LinkText">
                                      Set display name
                                    </Link>
                                  </div>
                                  <div className="spacer-10"></div>
                                  <div className="d-balance header-balance">
                                    {walletAddress === undefined ? (
                                      <></>
                                    ) : (
                                      <>
                                        <h4>Balance</h4>
                                        {balance} ETH
                                      </>
                                    )}{" "}
                                  </div>
                                  <div className="spacer-10"></div>
                                  <div className="d-wallet header-balance">
                                    <h4>My Wallet</h4>
                                    {walletAddress === undefined ? (
                                      <>
                                        <Link
                                          to={`/connect-wallet`}
                                          className="LinkText"
                                        >
                                          Connect Wallet
                                        </Link>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          id="wallet"
                                          className="header-wallet-address"
                                        >
                                          {walletAddress}
                                        </span>
                                        <button
                                          id="btn_copy"
                                          title="Copy Text"
                                          className="header-copy-btn"
                                          onClick={() => copyText("#wallet")}
                                        >
                                          Copy
                                        </button>
                                      </>
                                    )}
                                  </div>

                                  <div className="header-line"></div>

                                  <ul className="de-submenu-profile">
                                    <li>
                                      <Link
                                        to={`/creator-profile/${userid}`}
                                        className="LinkText"
                                      >
                                        <i className="fa fa-user">
                                          <FaUser />
                                        </i>{" "}
                                        My profile
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/profile" className="LinkText">
                                        <i className="fa fa-pencil">
                                          <TiPencil />
                                        </i>{" "}
                                        Edit profile
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        onClick={() => [
                                          setOpenSignout(!opensignOut),
                                        ]}
                                        className="LinkText"
                                      >
                                        <i className="fa fa-sign-out">
                                          <FaSignOutAlt />
                                        </i>{" "}
                                        Sign out
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              {/* <li className="profile-link">
                        </li> */}
                            </ul>
                            {/* <p style={{cursor:"auto"}}>{sessionStorage.getItem("walletAddress")}</p> */}
                          </li>
                          {/* <p title={sessionStorage.getItem("walletAddress")}>
                      {truncate(sessionStorage.getItem("walletAddress"), 10)}
                    </p> */}
                        </li>
                      </>
                    )}

                    {/* <li className="nav-tab">
                      <select className="language_drop ">
                        <option value="" disabled>
                          Select Language
                        </option>
                        <option value="english" selected>
                          English
                        </option>
                      </select>
                    </li> */}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={opensignOut}
        id="MainDiv"
        className="signout-modal"
        onClose={() => setOpenSignout(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-end">
          <div className="d-flex justify-content-end">
            <CgCloseO
              className="closeIconSty"
              onClick={() => [setOpenSignout(false)]}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">Are you sure you want to Sign Out?</h3>
          </DialogContentText>
          <Divider />
        </DialogContent>
        <div className="d-flex justify-content-center gap-4 mb-3">
          <button
            className="btn-main cancel-btn"
            onClick={() => setOpenSignout(false)}
          >
            No
          </button>
          <button className="btn-main" onClick={handleSignout}>
            Yes
          </button>
        </div>
      </Dialog>
    </>
  );
}

export default Header;

<header className="transparent scroll-dark">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="de-flex sm-pt10">
          <div className="de-flex-col">
            <div className="de-flex-col">
              {/* <!-- logo begin --> */}
              <div id="logo">
                <Link to="/">
                  <img
                    alt=""
                    src={logo}
                    style={{ height: "40px", width: "40px" }}
                  />
                </Link>
              </div>
              {/* <!-- logo close --> */}
            </div>
            <div className="de-flex-col">
              <input
                id="quick_search"
                className="xs-hide style-2"
                name="quick_search"
                placeholder="search item here..."
                type="text"
              />
            </div>
          </div>
          <div className="de-flex-col header-col-mid">
            {/* <!-- mainmenu begin --> */}
            <ul id="mainmenu">
              <li>
                <Link to="/">
                  Home
                  {/* <RiArrowDropDownLine /> */}
                </Link>
                {/* <ul>
                        <li>
                          <Link>Homepage 1</Link>
                        </li>
                        <li>
                          <Link>Homepage 2</Link>
                        </li>
                        <li>
                          <Link>New: Homepage 3</Link>
                        </li>
                      </ul> */}
              </li>
              <li>
                <Link to="/create-token">
                  Create
                  {/* <RiArrowDropDownLine /> */}
                </Link>
                {/* <ul>
                        <li>
                          <Link>Explore</Link>
                        </li>
                        <li>
                          <Link>Explore 2</Link>
                        </li>
                        <li>
                          <Link>New: Music NFT</Link>
                        </li>
                        <li>
                          <Link>Collections</Link>
                        </li>
                        <li>
                          <Link>Live Auction</Link>
                        </li>
                        <li>
                          <Link>Item Details</Link>
                        </li>
                        <li>
                          <Link>Help Center</Link>
                        </li>
                      </ul> */}
              </li>
              <li>
                <Link to="/explore">
                  Browse
                  {/* <RiArrowDropDownLine /> */}
                </Link>
              </li>
              <li>
                {/* <Link to="/">
                        Create Token
                        <RiArrowDropDownLine />
                      </Link> */}
                {/* <ul>
                        <li>
                          <Link>Author</Link>
                        </li>
                        <li>
                          <Link>Profile</Link>
                        </li>
                        <li>
                          <Link>Wallet</Link>
                        </li>
                        <li>
                          <Link>Create</Link>
                        </li>
                        <li>
                          <Link>Login</Link>
                        </li>
                      </ul> */}
              </li>
              {/* <  */}
            </ul>
            {/* <!-- mainmenu close --> */}
            <div className="menu_side_area">
              <div className="de-login-menu">
                <Link className="btn-main btn-wallet me-3" to="/login">
                  <span>Login</span>
                </Link>
                <Link className="btn-main btn-wallet" to="/connect-wallet">
                  <i className="icon_wallet_alt"></i>
                  <span>Connect TNT Wallet</span>
                </Link>

                <span
                  id="de-click-menu-profile"
                  className="de-menu-profile"
                  onClick={() => showInfo("#de-submenu-profile")}
                >
                  <img src={author1} className="img-fluid" alt="" />
                </span>
                <div className="de-submenu">
                  <div className="d-name">
                    <h4>Monica Lucas</h4>
                    <Link to="/profile" className="LinkText">
                      Set display name
                    </Link>
                  </div>
                  <div className="spacer-10"></div>
                  <div className="d-balance">
                    <h4>Balance</h4>
                    12.858 ETH
                  </div>
                  <div className="spacer-10"></div>
                  <div className="d-wallet">
                    <h4>My Wallet</h4>
                    <span id="wallet" className="d-wallet-address">
                      DdzFFzCqrhshMSxb9oW3mRo4MJrQkusV3fGFSTwaiu4wPBqMryA9DYVJCkW9n7twCffG5f5wX2sSkoDXGiZB1HPa7K7f865Kk4LqnrME
                    </span>
                    <button
                      id="btn_copy"
                      title="Copy Text"
                      onClick={() => copyText("#wallet")}
                    >
                      Copy
                    </button>
                  </div>

                  <div className="d-line"></div>

                  <ul className="de-submenu-profile">
                    <li>
                      <Link to="/creator-profile" className="LinkText">
                        <i className="fa fa-user">
                          <FaUser />
                        </i>{" "}
                        My profile
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile" className="LinkText">
                        <i className="fa fa-pencil">
                          <TiPencil />
                        </i>{" "}
                        Edit profile
                      </Link>
                    </li>
                    <li>
                      <Link to="/login" className="LinkText">
                        <i className="fa fa-sign-out">
                          <FaSignOutAlt />
                        </i>{" "}
                        Sign out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <span id="menu-btn" onClick={() => name()}></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>;
