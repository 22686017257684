import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_DOMAIN } from "../../utils/GlobalConst";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";

function ForgotPassword() {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotemailerr, setforgotemailerr] = useState("");
  const [forgoterr, setForgoterr] = useState("");
  const [forgotsuccess, setForgotsuccess] = useState("");
  const [forgotBtn, setForgotBtn] = useState(false);
  const [openforgot, setOpenforgot] = useState(false);

  const sendforgotemailhandler = () => {
    var isValidfor = true;
    if (forgotEmail === "") {
      isValidfor = false;
      setforgotemailerr("*Please Enter Email or Phone Number!");
    }
    setForgotBtn(false);
    return isValidfor;
  };

  const sendForgotEmailHandle = () => {
    if (sendforgotemailhandler()) {
      setForgotBtn(true);
      var API_URL = API_DOMAIN + "user/sendforgotpasswordlink";
      let headerConfig = {
        headers: {
          accept: "application/json",
        },
      };
      var data = {
        username: forgotEmail,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("response***", response);
          if (response.data.status === true) {
            setForgotBtn(false);
            setOpenforgot(true);
            setForgotsuccess(response?.data?.message);
            // toast.success(response?.data?.message);
            setforgotemailerr("");
          }
        })
        .catch(function (error) {
          console.log("catch block err***", error.response.data);
          setForgoterr(error.response.data.message);
          setForgotBtn(false);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            setForgoterr(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };

  const handleCloseforgot = () => {
    setOpenforgot(false);
    navigate("/login");
  };

  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (forgotBtn) {
        return;
      }
      sendForgotEmailHandle();
    }
  };

  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content" onKeyPress={keyHandler}>
        <div id="top"></div>

        <section className="full-height relative no-top no-bottom pt-5 mt-2 pb-5">
          <div className="overlay-gradient t50 p-0">
            <div className="center-y relative">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-lg-4 offset-lg-4 fadeIn bg-color"
                    data-wow-delay=".5s"
                  >
                    <div className="padding40 d-border">
                      <h3 className="mb10">Forgot Password</h3>
                      <div
                        name="contactForm"
                        id="contact_form"
                        className="form-border"
                        // method="post"
                        // action="blank.php"
                      >
                        <div className="field-set">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Email or Phone Number"
                            onChange={(e) => [
                              setForgotEmail(e.target.value),
                              setforgotemailerr(""),
                              setForgoterr(""),
                            ]}
                          />
                          <span className="starsty">{forgotemailerr}</span>
                        </div>
                        <div className="field-set mt-3">
                          <button
                            // type="submit"
                            // id="send_message"
                            // value="Reset Password"
                            className="btn btn-main btn-fullwidth color-2"
                            onClick={sendForgotEmailHandle}
                          >
                            Reset Password
                          </button>
                        </div>
                        <b>
                          {" "}
                          <center className="mt-2">
                            <span className="starsty mt-2">{forgoterr}</span>
                          </center>
                        </b>
                        <p className="forgotlink mt-1">
                          Back to{" "}
                          <Link to={"/login"} className="LinkText">
                            Sign In
                          </Link>
                          .
                        </p>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openforgot}
        onClose={handleCloseforgot}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <FcFeedback className="Email-icon" style={{ scale: "2.3" }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="forgotsuccess  mb-4">{forgotsuccess}</h3>
          </DialogContentText>
          <Divider />
        </DialogContent>
        <DialogActions>
          <button
            className="main-btn verify-ok-btn  mx-4"
            onClick={handleCloseforgot}
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>
      <Footer />
    </>
  );
}

export default ForgotPassword;
