import React, { useState } from "react";
import Header from "../Header/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import { API_DOMAIN } from "../../utils/GlobalConst";
import axios from "axios";

function VerifyPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openloginEmailInfo, setOpenloginEmailInfo] = useState(false);
  const [emailMobile, setEmailMobile] = useState("");
  const [ErremailMobile, setErrEmailMobile] = useState("");
  const [helperr, setHelperr] = useState("");
  const [sendLinkbtn, setSendLinkBtn] = useState(false);

  const resendValidation = () => {
    var isValidfor = true;
    if (emailMobile === "") {
      isValidfor = false;
      setErrEmailMobile("*Please Enter Email or Phone Number!");
    }
    setSendLinkBtn(false);
    return isValidfor;
  };

  const SendVerificationEmail = () => {
    if (resendValidation()) {
      setSendLinkBtn(true);
      var API_URL = API_DOMAIN + "user/resendverifylink";
      let headerConfig = {
        headers: {
          accept: "application/json",
        },
      };
      var data = {
        username: emailMobile,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("response***", response);
          if (response.data.status === true) {
            setSendLinkBtn(false);
            setOpenloginEmailInfo(true);
            // toast.success(response?.data?.message);
          }
        })
        .catch(function (error) {
          setHelperr(error?.response?.data?.message);
          console.log("catch block err***", error.response.data);
          setSendLinkBtn(false);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            setHelperr(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };
  const handleCloseloginEmailInfo = () => {
    setOpenloginEmailInfo(false);
    navigate("/login");
  };
  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        <section className="full-height relative no-top no-bottom pt-5 mt-2 pb-5">
          <div className="overlay-gradient t50 p-0">
            <div className="center-y relative">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-lg-4 offset-lg-4 fadeIn bg-color"
                    data-wow-delay=".5s"
                  >
                    <div className="padding40 d-border">
                      <h3 className="mb10">How Can We Help?</h3>
                      <p>Please Verify Your Account</p>
                      <div
                        name="contactForm"
                        id="contact_form"
                        className="form-border"
                        // method="post"
                        // action="blank.php"
                      >
                        <div className="field-set">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Enter Email or Phone Number"
                            onChange={(e) => [
                              setEmailMobile(e.target.value),
                              setErrEmailMobile(""),
                              setHelperr(""),
                            ]}
                          />
                          <span className="starsty">{ErremailMobile}</span>
                        </div>
                        <div className="field-set mt-3">
                          <button
                            type="submit"
                            // id="send_message"
                            // value="Send Link"
                            className="btn btn-main btn-fullwidth color-2"
                            onClick={SendVerificationEmail}
                          >
                            Send Link
                          </button>
                        </div>
                        <p className="forgotlink mt-1">
                          Back to{" "}
                          <Link to={"/login"} className="LinkText">
                            Sign In
                          </Link>
                          .
                        </p>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openloginEmailInfo}
        onClose={handleCloseloginEmailInfo}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <FcFeedback className="Email-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">Please Verify Your Account</h3>
            <h5 className="text-center">
              We have sent you a link on your registered email or phone no. to
              verify your Account.
            </h5>
            <h6 className="text-center">
              Please check your Spam or Junk folder
            </h6>
            <br />
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center mb-3">
            <button className="main-btn " onClick={handleCloseloginEmailInfo}>
              Close
            </button>
          </div>
        </>
      </Dialog>
      <Footer />
    </>
  );
}

export default VerifyPage;
