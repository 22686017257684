import React, { useState } from "react";
import Header from "../Header/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import "../Login/Login.css";
import axios from "axios";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import { API_DOMAIN } from "../../utils/GlobalConst";
function Login() {
  const navigate = useNavigate("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [email, setEmail] = useState("");
  const [passsword, setPasssword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [passVisibility, setPassVisibility] = useState(false);
  const [loginBtn, setLoginBtn] = useState(false);
  const [openloginEmailInfo, setOpenloginEmailInfo] = useState(false);

  const handleCloseloginEmailInfo = () => {
    setOpenloginEmailInfo(false);
  };

  const changeVisibility = (e) => {
    e.preventDefault();
    setPassVisibility(!passVisibility);
  };
  const LoginValidationHandler = () => {
    var isValidLogin = true;

    if (!email) {
      isValidLogin = false;
      setEmailErr("*Please Enter Email Or Phone Number!");
    }
    if (!passsword) {
      isValidLogin = false;
      setPassErr("*Please Enter Password!");
    }
    setLoginBtn(false);
    return isValidLogin;
  };

  const loginHandler = () => {
    if (LoginValidationHandler()) {
      setLoginBtn(true);
      var API_URL = API_DOMAIN + "user/login";
      let headerConfig = {
        headers: {
          accept: "application/json",
        },
      };
      var data = {
        username: email.toLowerCase(),
        password: passsword,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("response***", response);
          if (response.data.status === true) {
            setLoginBtn(false);
            setEmail("");
            sessionStorage.setItem("token", response.data.data.token);
            sessionStorage.setItem("userid", response.data.data.user_id);
            sessionStorage.setItem("useremail", response.data.data.email);
            sessionStorage.setItem("usermobile", response.data.data.mobile);
            sessionStorage.setItem(
              "userfirstname",
              response.data.data.firstname
            );
            sessionStorage.setItem("userlastname", response.data.data.lastname);
            navigate("/");
          }
        })
        .catch(function (error) {
          if (
            error.response.data.message === "Account inactive" ||
            error.response.data.message === "mobile number not verified" ||
            error.response.data.message === "email not verified"
          ) {
            setOpenloginEmailInfo(true);
          } else {
            // toast.error(error.response.data.message);
          }
          console.log("catch block err***", error.response.data);
          setLoginErr(error.response.data.message);
          setLoginBtn(false);
        })
        .then(function () {});
    }
  };
  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (loginBtn) {
        return;
      }
      loginHandler();
    }
  };
  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content" onKeyPress={keyHandler}>
        <div id="top"></div>

        <section className="full-height relative no-top no-bottom pt-5 mt-2 pb-5">
          <div className="overlay-gradient t50 p-0">
            <div className="center-y relative">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-lg-4 offset-lg-4 fadeIn bg-color"
                    data-wow-delay=".5s"
                  >
                    <div className="padding40 d-border">
                      <h3 className="mb10">Sign In</h3>
                      <p>
                        Login using an existing account or create a new account{" "}
                        <Link to="/register" className="LinkText">
                          here<span></span>
                        </Link>
                        .
                      </p>
                      <div
                        name="contactForm"
                        id="contact_form"
                        className="form-border"
                        // method="post"
                        // action="blank.php"
                      >
                        <div className="field-set">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="username"
                            onChange={(e) => [
                              setEmail(e.target.value),
                              setEmailErr(""),
                              setLoginErr(""),
                            ]}
                          />
                          <span className="starsty">{emailErr}</span>
                        </div>
                        <div className="field-set mt-3">
                          <div className="loc-group d-flex position-relative">
                            <input
                              type={passVisibility ? "text" : "password"}
                              name="password"
                              id="password"
                              className="form-control"
                              placeholder="password"
                              onChange={(e) => [
                                setPasssword(e.target.value),
                                setPassErr(""),
                                setLoginErr(""),
                              ]}
                            />
                            <span className="pass-show-eye">
                              <button
                                className="reg-eye"
                                onClick={(e) => changeVisibility(e)}
                              >
                                {passVisibility === true ? (
                                  <BsEyeFill />
                                ) : (
                                  <BsEyeSlashFill />
                                )}
                              </button>
                            </span>
                          </div>
                          <span className="starsty">{passErr}</span>
                        </div>
                        <p className="forgotlink">
                          <Link to={"/forgot-password"} className="LinkText">
                            Forgot Password?
                          </Link>
                        </p>

                        <div className="field-set">
                          <button
                            // type="submit"
                            // id="send_message"
                            // value="Submit"
                            onClick={loginHandler}
                            className="btn btn-main btn-fullwidth color-2"
                          >
                            Submit
                          </button>
                        </div>
                        <b>
                          {" "}
                          <center className="mt-2">
                            <span className="starsty mt-2">{loginErr}</span>
                          </center>
                        </b>
                        <p className="text-center fs-10 mt-1">
                          <Link to={"/verify-page"} className="LinkText">
                            Having Trouble Sign In?
                          </Link>
                        </p>
                        <div className="clearfix"></div>

                        {/* <div className="spacer-single"></div> */}

                        {/* <!-- social icons --> */}
                        {/* <ul className="list s3">
                          <li>Login with:</li>
                          <li>
                            <Link to="#" className="LinkText">
                              Facebook
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="LinkText">
                              Google
                            </Link>
                          </li>
                        </ul> */}
                        {/* <!-- social icons close --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openloginEmailInfo}
        onClose={() => setOpenloginEmailInfo(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <center>
            <FcFeedback className="Email-icon" />
          </center>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">Please Verify Your Account</h3>
            <h5 className="text-center">
              We have sent you a link on your registered email or phone no. to
              verify your Account.
            </h5>
            <h6 className="text-center">
              Please check your Spam or Junk folder
            </h6>
            <br />
            <center>
              <Link
                onClick={() => [
                  // openTroublePopupHandle(),
                  setLoginErr(""),
                  setEmailErr(""),
                  setPassErr(""),
                  setEmail(""),
                  setPasssword(""),
                  setOpenloginEmailInfo(false),
                ]}
                style={{ cursor: "pointer" }}
                className="LinkText"
              >
                <u>Resend verification link</u>
              </Link>
            </center>
            {/* <p className="text-center">
              <Link to="/support" className="text_account">
                <u>Resend verification link</u>
              </Link>
            </p> */}
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center mb-3">
            <button
              className="popup_btn_yes "
              onClick={handleCloseloginEmailInfo}
            >
              Okay
            </button>
          </div>
        </>
      </Dialog>
      <Footer />
    </>
  );
}

export default Login;
