import React from "react";
import "../Footer/Footer.css";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn, FaPinterest, FaRss, FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-1">
              <div className="widget">
                <Link to="/" className="FooterLink">
                  <h5>Home</h5>
                </Link>
                {/* <ul>
                  <li>
                    <Link to="#">All NFTs</Link>
                  </li>
                  <li>
                    <Link to="#">Art</Link>
                  </li>
                  <li>
                    <Link to="#">Music</Link>
                  </li>
                  <li>
                    <Link to="#">Domain Names</Link>
                  </li>
                  <li>
                    <Link to="#">Virtual World</Link>
                  </li>
                  <li>
                    <Link to="#">Collectibles</Link>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-1">
              <div className="widget">
                <Link to="/create-token" className="LinkText FooterLink">
                  <h5>Create</h5>
                </Link>

                {/* <ul>
                  <li>
                    <Link to="#">Help Center</Link>
                  </li>
                  <li>
                    <Link to="#">Partners</Link>
                  </li>
                  <li>
                    <Link to="#">Suggestions</Link>
                  </li>
                  <li>
                    <Link to="#">Discord</Link>
                  </li>
                  <li>
                    <Link to="#">Docs</Link>
                  </li>
                  <li>
                    <Link to="#">Newsletter</Link>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-1">
              <div className="widget">
                <Link to="/explore" className="LinkText FooterLink">
                  <h5>Explore</h5>
                </Link>
                {/* <ul>
                  <li>
                    <Link to="#">Community</Link>
                  </li>
                  <li>
                    <Link to="#">Documentation</Link>
                  </li>
                  <li>
                    <Link to="#">Brand Assets</Link>
                  </li>
                  <li>
                    <Link to="#">Blog</Link>
                  </li>
                  <li>
                    <Link to="#">Forum</Link>
                  </li>
                  <li>
                    <Link to="#">Mailing List</Link>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-1">
              <div className="widget">
                <h5>Newsletter</h5>
                <p>
                  Signup for our newsletter to get the latest news in your
                  inbox.
                </p>
                <form
                  action="blank.php"
                  className="row form-dark"
                  id="form_subscribe"
                  method="post"
                  name="form_subscribe"
                >
                  <div className="col text-center">
                    <input
                      className="form-control"
                      id="txt_subscribe"
                      name="txt_subscribe"
                      placeholder="enter your email"
                      type="text"
                    />{" "}
                    <Link to="#" id="btn-subscribe">
                      <i className="arrow_right bg-color-secondary">
                        <FaArrowRightLong className="footer-email" />
                      </i>
                    </Link>
                    <div className="clearfix"></div>
                  </div>
                </form>
                <div className="spacer-10"></div>
                <small>Your email is safe with us. We don't spam.</small>
              </div>
            </div>
          </div>
        </div>
        <div className="subfooter">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="de-flex">
                  <div className="de-flex-col">
                    
                      <span className="copy">&copy; Copyright 2024</span>
                    
                  </div>
                  <div className="de-flex-col">
                    <div className="social-icons">
                      <Link to="#">
                        <i className="fa fa-facebook fa-lg">
                          <FaFacebookF className="footerIcon" />
                        </i>
                      </Link>
                      <Link to="#">
                        <i className="fa fa-twitter fa-lg">
                          <FaTwitter className="footerIcon" />
                        </i>
                      </Link>
                      <Link to="#">
                        <i className="fa fa-linkedin fa-lg">
                          <FaLinkedinIn className="footerIcon" />
                        </i>
                      </Link>
                      <Link to="#">
                        <i className="fa fa-pinterest fa-lg">
                          <FaPinterest className="footerIcon" />
                        </i>
                      </Link>
                      <Link to="#">
                        <i className="fa fa-rss fa-lg">
                          <FaRss className="footerIcon" />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
