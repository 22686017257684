import React, { useEffect, useState } from "react";
import "../Card/Card.css";
import { Link, useNavigate } from "react-router-dom";
import {
  FaCheck,
  FaEnvelope,
  FaFacebookF,
  FaHeart,
  FaRegHeart,
  FaTwitter,
} from "react-icons/fa";
import author1 from "../../Assets/images/author/author-1.jpg";
import img1 from "../../Assets/images/tntimages/Logo.png";
import $ from "jquery";
import { API_DOMAIN } from "../../utils/GlobalConst";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Tooltip } from "@mui/material";
function Card() {
  const navigate = useNavigate();
  const [CardData, setCardData] = useState([]);
  const [nextToken, setNextToken] = useState("");
  const [IndexData, setIndexData] = useState("");
  const [hasMorecards, setHasMorecards] = useState("");
  const userid = sessionStorage.getItem("userid");

  useEffect(() => {
    $(".nft__item_click").on("click", function () {
      var iteration = $(this).data("iteration") || 1;

      switch (iteration) {
        case 1:
          var cover = $(this).parent().parent().find(".nft__item_extra");
          cover.css("visibility", "visible");
          cover.css("opacity", "1");
          break;
        case 2:
          var cover = $(this).parent().parent().find(".nft__item_extra");
          cover.css("visibility", "hidden");
          cover.css("opacity", "0");
          break;
      }
      iteration++;
      if (iteration > 2) iteration = 1;
      $(this).data("iteration", iteration);
    });

    $(".nft__item").mouseleave(function () {
      var cover = $(this).find(".nft__item_extra");
      cover.css("visibility", "hidden");
      cover.css("opacity", "0");
      $(this).find(".nft__item_click").data("iteration", 1);
    });

    // $(".nft__item_like").on("click", function () {
    //   var iteration = $(this).data("iteration") || 1;

    //   switch (iteration) {
    //     case 1:
    //       $(this).find("i").addClass("active");
    //       var val = parseInt($(this).find("span").text()) + 1;
    //       $(this).find("span").text(val);
    //       break;
    //     case 2:
    //       $(this).find("i").removeClass("active");
    //       var val = parseInt($(this).find("span").text()) - 1;
    //       $(this).find("span").text(val);
    //       break;
    //   }
    //   iteration++;
    //   if (iteration > 2) iteration = 1;
    //   $(this).data("iteration", iteration);
    // });
  });

  const getCard = () => {
    var API_URL = API_DOMAIN + "image/selectall";
    axios
      .get(API_URL)
      .then((response) => {
        var res = response.data.data;
        const sortdata = res.imageData;
        sortdata.sort(
          (a, b) => new Date(a?.StartDate) - new Date(b?.StartDate)
        );
        console.log("sortdata", sortdata);
        // setCardData(sortdata);
        handleLikeShow(sortdata);
        setNextToken(res.nexttoken);
        setHasMorecards(res.hasMoreimageDatas);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const getLoginData = () => {
    var API_URL = API_DOMAIN + `image/selectall?limit=12&userid=${userid}`;
    axios
      .get(API_URL)
      .then((response) => {
        var res = response.data.data;
        const sortdata = res.imageData;
        sortdata.sort(
          (a, b) => new Date(a?.StartDate) - new Date(b?.StartDate)
        );
        console.log("sortdata", sortdata);

        handleLikeShow(sortdata);
        setNextToken(res.nexttoken);
        setHasMorecards(res.hasMoreimageDatas);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const handleLikeShow = (sortdata) => {
    for (let i = 0; i < sortdata.length; i++) {
      const arr = [];
      const element = sortdata[i].LikeData;
      console.log(element);
      for (let j = 0; j < element.length; j++) {
        if (element[j].userid === sessionStorage.getItem("userid")) {
          console.log("match", sortdata[i]);
          sortdata[i].isLike = true;
          console.log((sortdata[i].isLike = true));
        }
      }
    }
    console.log(sortdata);
    setCardData(sortdata);
  };
  useEffect(() => {
    getCard();
    if (sessionStorage.getItem("token") !== null) {
      console.log("in if", sessionStorage.getItem("token"));
      getLoginData();
    }
  }, []);

  const getMoreCard = (nextToken, D) => {
    var API_URL =
      API_DOMAIN +
      `image/selectall?limit=12&nexttoken=${nextToken}&userid=${userid}`;

    axios
      .get(API_URL)
      .then(function (response) {
        var res = response.data.data.imageData;
        for (let i = 0; i < res.length; i++) {
          D.push(res[i]);
          const sortdata = D;
          sortdata.sort(
            (a, b) => new Date(a?.StartDate) - new Date(b?.StartDate)
          );
          setCardData(sortdata);
        }

        setHasMorecards(res.hasMoreimageDatas);
        setNextToken(res.nexttoken);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const LikeAddCountHandle = (imgid) => {
    var API_URL = API_DOMAIN + "like/addupdate";
    let headerConfig = {
      headers: {
        accept: "application/json",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      imageUpload_id: imgid,
      userid: sessionStorage.getItem("userid"),
      isLike: true,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("click Like btn", response.data.data);
        if (response.status === 200) {
          getLoginData();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.info("Please Login First");
        }
      })
      .then(function () {});
  };
  const LikeRemoveCountHandle = (imgid) => {
    var API_URL = API_DOMAIN + "like/addupdate";
    let headerConfig = {
      headers: {
        accept: "application/json",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      imageUpload_id: imgid,
      userid: sessionStorage.getItem("userid"),
      isLike: false,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("click Like Remove btn", response.data.data);
        if (response.status === 200) {
          getLoginData();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.info("Please Login First");
        }
      })
      .then(function () {});
  };
  const [opentooltip, setOpenTooltip] = useState(false);

  const [openFacebook, setOpenFacebook] = useState(false);
  const [openTwitter, setOpenTwitter] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);

  const handleTooltipOpenFacebook = () => {
    setOpenFacebook(true);
    setTimeout(() => {
      setOpenFacebook(false);
    }, [1000]);
    setOpenTooltip(true);
  };
  const handleTooltipOpenTwitter = () => {
    setOpenTwitter(true);
    setTimeout(() => {
      setOpenTwitter(false);
    }, [1000]);
    setOpenTooltip(true);
  };

  const handleTooltipOpenEmail = () => {
    setOpenEmail(true);
    setTimeout(() => {
      setOpenEmail(false);
    }, [1000]);
    setOpenTooltip(true);
  };
  const handleCopyUrl = (sm, id) => {
    navigator.clipboard.writeText(
      `https://www.art.green-coin.org/item-details/${id}`
    );

    if (sm === "facebook") {
      handleTooltipOpenFacebook();
      setTimeout(() => {
        window.open("https://www.facebook.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    } else if (sm === "twitter") {
      handleTooltipOpenTwitter();
      setTimeout(() => {
        window.open("https://twitter.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    } else if (sm === "email") {
      handleTooltipOpenEmail();
      setTimeout(() => {
        window.open("https://gmail.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    }
  };

  return (
    <>
      <section id="section-collections" className="pt-0 pb-0">
        <div className="">
          <div className="row fadeIn">
            {/* <!-- nft item begin --> */}
            {CardData.length > 0 &&
              CardData.map((cardinfo, index) => {
                return (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 ">
                      <div className="nft__item">
                        {cardinfo.showtime === true ? (
                          <>
                            {" "}
                            <div
                              className="de_countdown"
                              style={{
                                backgroundColor: "#FEF006",
                                color: "white",
                              }}
                            >
                              <>{cardinfo.time}</>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="author_list_pp">
                          <Link
                            to={`/creator-profile/${cardinfo.UserDetails[0]?.userid}`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Creator: Monica Lucas"
                          >
                            <img
                              className="lazy"
                              src={
                                cardinfo.UserDetails[0]?.ProfileURL === "" ||
                                cardinfo.UserDetails[0]?.ProfileURL ===
                                  undefined
                                  ? author1
                                  : cardinfo.UserDetails[0]?.ProfileURL
                              }
                              alt=""
                            />
                            <i className="fa fa-check">
                              <FaCheck />
                            </i>
                          </Link>
                        </div>
                        <div className="nft__item_wrap">
                          <div className="nft__item_extra">
                            <div className="nft__item_buttons">
                              <button
                                onClick={() =>
                                  navigate(
                                    `/item-details/${cardinfo.imageUpload_id}`
                                  )
                                }
                              >
                                Buy Now
                              </button>
                              <div className="nft__item_share">
                                <h4>Share</h4>
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  arrow
                                  open={openFacebook}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title="Copied!"
                                  onClose={() => setOpenTooltip(false)}
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      handleCopyUrl(
                                        "facebook",
                                        cardinfo.imageUpload_id
                                      )
                                    }
                                  >
                                    <i className="fa fa-facebook fa-lg">
                                      {" "}
                                      <FaFacebookF />
                                    </i>
                                  </Link>
                                </Tooltip>
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  arrow
                                  open={openTwitter}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title="Copied!"
                                  onClose={() => setOpenTooltip(false)}
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      handleCopyUrl(
                                        "twitter",
                                        cardinfo.imageUpload_id
                                      )
                                    }
                                  >
                                    <i className="fa fa-twitter fa-lg">
                                      {" "}
                                      <FaTwitter />
                                    </i>
                                  </Link>
                                </Tooltip>
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  arrow
                                  open={openEmail}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title="Copied!"
                                  onClose={() => setOpenTooltip(false)}
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      handleCopyUrl(
                                        "email",
                                        cardinfo.imageUpload_id
                                      )
                                    }
                                  >
                                    <i className="fa fa-envelope fa-lg">
                                      <FaEnvelope />
                                    </i>
                                  </Link>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <Link to={`/item-details/${cardinfo.imageUpload_id}`}>
                            <img
                              src={
                                cardinfo.fileURL === ""
                                  ? img1
                                  : cardinfo.fileURL
                              }
                              className="lazy cardimgstyle nft__item_preview"
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="nft__item_info">
                          <Link
                            to={`/item-details/${cardinfo.imageUpload_id}`}
                            className="LinkText"
                          >
                            <h4>{cardinfo.NameofPainting}</h4>
                          </Link>
                          <div className="nft__item_click">
                            <span></span>
                          </div>
                          <div className="nft__item_price">
                            {cardinfo.price} ETH
                            <span>1/20</span>
                          </div>
                          <div className="nft__item_action">
                            <Link
                              to={`/item-details/${cardinfo.imageUpload_id}`}
                              className="LinkText"
                            >
                              Buy Now{" "}
                            </Link>
                          </div>
                          <div className="nft__item_like d-flex">
                            <div onClick={() => setIndexData(index)}>
                              {console.log(cardinfo?.isLike)}
                              {cardinfo?.isLike === true ? (
                                <>
                                  {" "}
                                  <FaHeart
                                    style={{ color: "#dd4848" }}
                                    onClick={() => [
                                      LikeRemoveCountHandle(
                                        cardinfo.imageUpload_id
                                      ),
                                    ]}
                                  />
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <FaRegHeart
                                    style={{ color: "#dd4848" }}
                                    onClick={() => [
                                      LikeAddCountHandle(
                                        cardinfo.imageUpload_id
                                      ),
                                    ]}
                                  />
                                </>
                              )}
                            </div>
                            <span className="mt-1">
                              {cardinfo.LikeData.length}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            {hasMorecards ? (
              <>
                <div className="browse-btn d-flex justify-content-center mt-3">
                  <button
                    onClick={() => getMoreCard(nextToken, CardData)}
                    className="btn btn-main color-2 fs-5"
                  >
                    Browse More
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default Card;
