import React, { useEffect } from "react";
import { FaCheck, FaRegHeart, FaSearch } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import $ from "jquery";
import author1 from "../../Assets/images/author/author-1.jpg";
import static1 from "../../Assets/images/items-alt/static-1.jpg";

import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Card from "../Card/Card";
import Footer from "../Footer/Footer";

function dropdown(e) {
  var obj = $(e + ".dropdown");
  var btn = obj.find(".btn-selector");
  var dd = obj.find("ul");
  var opt = dd.find("li");

  obj
    .on("mouseenter", function () {
      dd.show();
      $(this).css("z-index", 1000);
    })
    .on("mouseleave", function () {
      dd.hide();
      $(this).css("z-index", "auto");
    });

  opt.on("click", function () {
    dd.hide();
    var txt = $(this).text();
    opt.removeClass("active");
    $(this).addClass("active");
    btn.text(txt);
  });
}

function Explore() {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        {/* <!-- section begin --> */}
        <section id="subheader" className="pt-0">
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Explore</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section close --> */}

        {/* <!-- section begin --> */}
        <section aria-label="section">
          <div className="container">
            <div className="row  fadeIn">
              <div className="col-lg-12">
                <div className="items_filter">
                  <form
                    action="blank.php"
                    className="row form-dark"
                    id="form_quick_search"
                    method="post"
                    name="form_quick_search"
                  >
                    <div className="col text-center">
                      <input
                        className="form-control"
                        id="name_1"
                        name="name_1"
                        placeholder="search item here..."
                        type="text"
                      />{" "}
                      <a href="#" id="btn-submit">
                        <i className="fa fa-search bg-color-secondary">
                          <FaSearch />
                        </i>
                      </a>
                      <div className="clearfix"></div>
                    </div>
                  </form>

                  <div
                    id="item_category"
                    className="dropdown"
                    onMouseEnter={(e) => dropdown("#item_category")}
                  >
                    <a href="#" className="btn-selector">
                      All categories <RiArrowDropDownLine />{" "}
                    </a>
                    <ul>
                      <li className="active">
                        <span>All categories</span>
                      </li>
                      <li>
                        <span>Art</span>
                      </li>
                      <li>
                        <span>Music</span>
                      </li>
                      <li>
                        <span>Domain Names</span>
                      </li>
                      <li>
                        <span>Virtual World</span>
                      </li>
                      <li>
                        <span>Trading Cards</span>
                      </li>
                      <li>
                        <span>Collectibles</span>
                      </li>
                      <li>
                        <span>Sports</span>
                      </li>
                      <li>
                        <span>Utility</span>
                      </li>
                    </ul>
                  </div>

                  <div
                    id="buy_category"
                    className="dropdown"
                    onMouseEnter={(e) => dropdown("#buy_category")}
                  >
                    <a href="#" className="btn-selector">
                      Buy Now <RiArrowDropDownLine />
                    </a>
                    <ul>
                      <li className="active">
                        <span>Buy Now</span>
                      </li>
                      <li>
                        <span>On Auction</span>
                      </li>
                      <li>
                        <span>Has Offers</span>
                      </li>
                    </ul>
                  </div>

                  <div
                    id="items_type"
                    className="dropdown"
                    onMouseEnter={(e) => dropdown("#items_type")}
                  >
                    <a href="#" className="btn-selector">
                      All Items <RiArrowDropDownLine />
                    </a>
                    <ul>
                      <li className="active">
                        <span>All Items</span>
                      </li>
                      <li>
                        <span>Single Items</span>
                      </li>
                      <li>
                        <span>Bundles</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- nft item begin --> */}
              <Card />

              {/* <div className="col-md-12 text-center">
                <a href="#" id="loadmore" className="btn-main  fadeInUp lead">
                  Load more
                </a>
              </div> */}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Explore;
