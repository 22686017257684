import React, { useState } from "react";
import "../ConnectWallet/ConnectWallet.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import Metamask from "../../Assets/images/wallet/1.png";
import Bitski from "../../Assets/images/wallet/2.png";
import Fortmatic from "../../Assets/images/wallet/3.png";
import WalletConnect from "../../Assets/images/wallet/4.png";
import CoinbaseWallet from "../../Assets/images/wallet/5.png";
import Arkane from "../../Assets/images/wallet/6.png";
import Authereum from "../../Assets/images/wallet/7.png";
import Torus from "../../Assets/images/wallet/8.png";
import Web3 from "web3";
function ConnectWallet() {
  const [connectedAccount, setConnectedAccount] = useState("null");
  async function connectMetamask() {
    //check metamask is installed
    if (window.ethereum) {
      // instantiate Web3 with the injected provider
      const web3 = new Web3(window.ethereum);

      //request user to connect accounts (Metamask will prompt)
      await window.ethereum.request({ method: "eth_requestAccounts" });

      //get the connected accounts
      const accounts = await web3.eth.getAccounts();

      //show the first connected account in the react page
      setConnectedAccount(accounts[0]);
    } else {
      alert("Please download metamask");
    }
  }
  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        {/* <!-- section begin --> */}
        <section id="subheader" className="text-light">
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Wallet</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section close -->             */}

        {/* <!-- section begin --> */}
        <section aria-label="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mb30">
                <button
                  className="box-url walletlink"
                  onClick={connectMetamask}
                >
                  <span className="box-url-label">Most Popular</span>
                  <img src={Metamask} alt="" className="mb20" />
                  <h4>Metamask</h4>
                  <p>
                    Start exploring blockchain applications in seconds. Trusted
                    by over 1 million users worldwide.
                  </p>
                </button>
              </div>

              <div className="col-lg-3 mb30">
                <Link className="box-url walletlink" to="#">
                  <img src={Bitski} alt="" className="mb20" />
                  <h4>Bitski</h4>
                  <p>
                    Bitski connects communities, creators and brands through
                    unique, ownable digital content.
                  </p>
                </Link>
              </div>

              <div className="col-lg-3 mb30">
                <Link className="box-url walletlink" to="#">
                  <img src={Fortmatic} alt="" className="mb20" />
                  <h4>Fortmatic</h4>
                  <p>
                    Let users access your Ethereum app from anywhere. No more
                    browser extensions.
                  </p>
                </Link>
              </div>

              <div className="col-lg-3 mb30">
                <Link className="box-url walletlink" to="#">
                  <img src={WalletConnect} alt="" className="mb20" />
                  <h4>WalletConnect</h4>
                  <p>
                    Open source protocol for connecting decentralised
                    applications to mobile wallets.
                  </p>
                </Link>
              </div>

              <div className="col-lg-3 mb30">
                <Link className="box-url walletlink" to="#">
                  <img src={CoinbaseWallet} alt="" className="mb20" />
                  <h4>Coinbase Wallet</h4>
                  <p>
                    The easiest and most secure crypto wallet. ... No Coinbase
                    account required.
                  </p>
                </Link>
              </div>

              <div className="col-lg-3 mb30">
                <Link className="box-url walletlink" to="#">
                  <img src={Arkane} alt="" className="mb20" />
                  <h4>Arkane</h4>
                  <p>
                    Make it easy to create blockchain applications with secure
                    wallets solutions.
                  </p>
                </Link>
              </div>

              <div className="col-lg-3 mb30">
                <Link className="box-url walletlink" to="#">
                  <img src={Authereum} alt="" className="mb20" />
                  <h4>Authereum</h4>
                  <p>
                    Your wallet where you want it. Log into your favorite dapps
                    with Authereum.
                  </p>
                </Link>
              </div>

              <div className="col-lg-3 mb30">
                <Link className="box-url walletlink" to="#">
                  <span className="box-url-label">Most Simple</span>
                  <img src={Torus} alt="" className="mb20" />
                  <h4>Torus</h4>
                  <p>
                    Open source protocol for connecting decentralised
                    applications to mobile wallets.
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default ConnectWallet;
