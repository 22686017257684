import React, { useEffect, useState } from "react";
import "../DetailPage/DetailPage.css";
import Header from "../Header/Header";
import img1 from "../../Assets/images/tntimages/Logo.png";
// import img1 from "../../Assets/images/items/big-4.jpg";
import "../../Assets/css/coloring.css";
import "../../Assets/css/de-black.css";
import "../../Assets/css/style.css";
import "../../Assets/css/bootstrap.min.css";
import "../../Assets/css/plugins.css";
import "../../Assets/css/coloring-gradient.css";
import "../../Assets/css/bootstrap.rtl.min.css";
import Footer from "../Footer/Footer";
import author1 from "../../Assets/images/author/author-1.jpg";
import thumbnail1 from "../../Assets/images/collections/coll-thumbnail-1.jpg";
import ethImg from "../../Assets/images/misc/ethereum.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaCheck, FaHeart } from "react-icons/fa";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { API_DOMAIN } from "../../utils/GlobalConst";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CgCloseO } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";
import { getAvailabeToken } from "../../utils/ContractCaller";
const ethers = require("ethers");

function DetailPage() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { imageID } = useParams();
  const navigate = useNavigate();
  const [CardData, setCardData] = useState([]);
  useEffect(() => {
    $(".de_tab").find(".de_tab_content > div").hide();
    $(".de_tab").find(".de_tab_content > div:first").show();
    $("li").find(".v-border").fadeTo(150, 0);
    $("li.active").find(".v-border").fadeTo(150, 1);
    $(".de_nav li").on("click", function () {
      $(this).parent().find("li").removeClass("active");
      $(this).addClass("active");
      $(this).parent().parent().find(".v-border").fadeTo(150, 0);
      $(this).parent().parent().find(".de_tab_content > div").hide();
      var indexer = $(this).index(); //gets the current index of (this) which is #nav li
      $(this)
        .parent()
        .parent()
        .find(".de_tab_content > div:eq(" + indexer + ")")
        .fadeIn(); //uses whatever index the link has to open the corresponding box
      $(this).find(".v-border").fadeTo(150, 1);
    });
  });

  useEffect(() => {
    var image_ID = imageID;
    var API_URL =
      API_DOMAIN + `image/select/imageUploadid?imageUpload_id=${image_ID}`;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("card details ", response);
        var res = response.data.data;
        setCardData(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, []);
  const [showbuyPopup, setShowbuyPopup] = useState(false);
  const [buyQuantity, setBuyQuantity] = useState(1);
  const [error, setError] = useState();

  const checkBuyConditions = () => {
    if (
      sessionStorage.getItem("token") === "" ||
      sessionStorage.getItem("token") === undefined ||
      sessionStorage.getItem("token") === null
    ) {
      toast.info("Please Login First!");
      console.log("dskfhkafkjhdjk");
    } else {
      setShowbuyPopup(true);
    }
  };

  const networks = {
    andrena: {
      chainId: `0x${Number(9343130).toString(16)}`,
      chainName: "Andrena Test Net",
      nativeCurrency: {
        name: "DAWN2",
        symbol: "DWN2",
        decimals: 18,
      },
      rpcUrls: ["https://andrena-devnet.calderachain.xyz/http"],
      blockExplorerUrls: ["https://andrena-devnet.calderaexplorer.xyz/token/"],
    },
  };
  const changeNetwork = async ({ networkName }) => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...networks[networkName],
          },
        ],
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleNetworkSwitch = (networkName, fileid, contractAddress, qty) => {
    console.log(window.ethereum.networkVersion);
    const price = qty * CardData[0]?.price;
    if (Number(window.ethereum.networkVersion) === 9343130) {
      console.log("Buyyyyy");
      buytoken(fileid, contractAddress, qty, price);
    } else {
      changeNetwork({ networkName });
    }
  };
  useEffect(() => {
    console.log(CardData);
    getAvailabeToken(CardData[0]?.fileid, CardData[0]?.contractaddress);
  }, [CardData, showbuyPopup]);

  const buytoken = (fileid, contractAddress, qty, price) => {
    var API_URL = "https://tnt.moramba.io:8888/contracts/" + fileid + ".json";
    axios
      .get(API_URL)
      .then(async (response) => {
        console.log("00000 ", response.data);
        var contractAbi = response.data.abi;
        //contractAddress="0xAa29157AeA2a2Fd3B8d256861Ca263226E41cf1b";
        const provider = new ethers.BrowserProvider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(
          contractAddress,
          contractAbi,
          signer
        );
        const txResponse = await contract.buyMinted(qty, { value: price });
        await txResponse.wait(); // Wait for the transaction to be mined
        await buyhandle(contractAddress, fileid);
        await setShowbuyPopup(false);
        console.log("Transaction Hash:", txResponse.hash);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };
  const buyhandle = (contractAddress, fileid) => {
    var API_URL = API_DOMAIN + `mycontract/create`;
    let headerConfig = {
      headers: {
        accept: "application/json",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      userid: sessionStorage.getItem("userid"),
      contractaddress: contractAddress,
      fileid: fileid,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((response) => {
        console.log("buy ", response);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  return (
    <>
      <Header />
      {/* <!-- content begin --> */}
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        <section aria-label="section" className="p-0 sm-mt-0 mb-5">
          <div className="container">
            {CardData.map((cardinfo) => {
              return (
                <>
                  <div className="row">
                    <div className="col-md-6 text-center">
                      <img
                        src={cardinfo.fileURL === "" ? img1 : cardinfo.fileURL}
                        className="img-fluid img-rounded mb-sm-30 img-detailpage"
                        alt=""
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="item_info">
                        Auctions ends in{" "}
                        <div
                          className="de_countdown"
                          data-year="2022"
                          data-month="6"
                          data-day="16"
                          data-hour="8"
                        ></div>
                        <h2>
                          {cardinfo.NameofPainting} &nbsp; #{cardinfo.tokenname}
                        </h2>
                        <div className="item_info_counts">
                          <div className="item_info_type">
                            <i className="fa fa-image"></i>Art
                          </div>
                          {/* <div className="item_info_views">
                            <i className="fa fa-eye"></i>250
                          </div> */}
                          <div className="item_info_like">
                            {/* <i className="fa fa-heart"></i> */}
                            <FaHeart style={{ color: "yellow" }} /> &nbsp;
                            {cardinfo.LikeData.length}
                          </div>
                        </div>
                        <p>{cardinfo.DescriptionofPainting}</p>
                        <div className="d-flex flex-row">
                          <div className="mr40">
                            <h6>Creator</h6>
                            <div className="item_author">
                              <div className="author_list_pp">
                                <Link
                                  to={`/creator-profile/${cardinfo.UserDetails[0]?.userid}`}
                                  className="LinkText"
                                >
                                  <img
                                    className="lazy"
                                    src={
                                      cardinfo.UserDetails[0]?.ProfileURL ===
                                        "" ||
                                      cardinfo.UserDetails[0]?.ProfileURL ===
                                        undefined
                                        ? author1
                                        : cardinfo.UserDetails[0]?.ProfileURL
                                    }
                                    alt=""
                                  />
                                  <i className="fa fa-check">
                                    <FaCheck />
                                  </i>
                                </Link>
                              </div>
                              <div className="author_list_info">
                                <Link
                                  to={`/creator-profile/${cardinfo.UserDetails[0]?.userid}`}
                                  className="LinkText"
                                >
                                  {cardinfo.UserDetails[0]?.firstname}{" "}
                                  {cardinfo.UserDetails[0]?.lastname}
                                </Link>
                              </div>
                            </div>
                          </div>
                          {/* <div>
                            <h6>Collection</h6>
                            <div className="item_author">
                              <div className="author_list_pp">
                                <Link to="#">
                                  <img
                                    className="lazy"
                                    src={thumbnail1}
                                    alt=""
                                  />
                                  <i className="fa fa-check">
                                    <FaCheck />
                                  </i>
                                </Link>
                              </div>
                              <div className="author_list_info">
                                <Link to="#" className="LinkText">
                                  AnimeSailorClub
                                </Link>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div className="spacer-40"></div>
                        <div className="de_tab tab_simple">
                          <ul className="de_nav">
                            {/* <li>
                              <span>Details</span>
                            </li> */}
                            <li className="active">
                              <span>Bids</span>
                            </li>
                            <li>
                              <span>History</span>
                            </li>
                          </ul>
                          <div className="de_tab_content">
                            {/* <div className="tab-1">
                              <h6>Owner</h6>
                              <div className="item_author">
                                <div className="author_list_pp">
                                  <Link to="#">
                                    <img
                                      className="lazy"
                                      src={author1}
                                      alt=""
                                    />
                                    <i className="fa fa-check">
                                      <FaCheck />
                                    </i>
                                  </Link>
                                </div>
                                <div className="author_list_info">
                                  <Link
                                    to="#"
                                    className="LinkText"
                                  >
                                    Stacy Long
                                  </Link>
                                </div>
                              </div>

                              <div className="spacer-40"></div>
                              <h6>Properties</h6>
                              <div className="row gx-2">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <Link to="#" className="LinkText nft_attr">
                                    <h5>Background</h5>
                                    <h4>Yellowish Sky</h4>
                                    <span>85% have this trait</span>
                                  </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <Link to="#" className="LinkText nft_attr">
                                    <h5>Eyes</h5>
                                    <h4>Purple Eyes</h4>
                                    <span>14% have this trait</span>
                                  </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <Link to="#" className="LinkText nft_attr">
                                    <h5>Nose</h5>
                                    <h4>Small Nose</h4>
                                    <span>45% have this trait</span>
                                  </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <Link to="#" className="LinkText nft_attr">
                                    <h5>Mouth</h5>
                                    <h4>Smile Red Lip</h4>
                                    <span>61% have this trait</span>
                                  </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <Link to="#" className="LinkText nft_attr">
                                    <h5>Neck</h5>
                                    <h4>Pink Ribbon</h4>
                                    <span>27% have this trait</span>
                                  </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <Link to="#" className="LinkText nft_attr">
                                    <h5>Hair</h5>
                                    <h4>Pink Short</h4>
                                    <span>35% have this trait</span>
                                  </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <Link to="#" className="LinkText nft_attr">
                                    <h5>Accessories</h5>
                                    <h4>Heart Necklace</h4>
                                    <span>33% have this trait</span>
                                  </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <Link to="#" className="LinkText nft_attr">
                                    <h5>Hat</h5>
                                    <h4>Cute Panda</h4>
                                    <span>62% have this trait</span>
                                  </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <Link to="#" className="LinkText nft_attr">
                                    <h5>Clothes</h5>
                                    <h4>Casual Purple</h4>
                                    <span>78% have this trait</span>
                                  </Link>
                                </div>
                              </div>
                              <div className="spacer-30"></div>
                            </div> */}

                            <div className="tab-1">
                              <div className="p_list">
                                <div className="p_list_pp">
                                  <a href="05_black-author.html">
                                    <img
                                      className="lazy"
                                      src={author1}
                                      alt=""
                                    />
                                    <i className="fa fa-check">
                                      <FaCheck />
                                    </i>
                                  </a>
                                </div>
                                <div className="p_list_info">
                                  Bid accepted <b>0.005 ETH</b>
                                  <span>
                                    by <b>Monica Lucas</b> at 6/15/2021, 3:20 AM
                                  </span>
                                </div>
                              </div>

                              <div className="p_list">
                                <div className="p_list_pp">
                                  <a href="05_black-author.html">
                                    <img
                                      className="lazy"
                                      src={author1}
                                      alt=""
                                    />
                                    <i className="fa fa-check">
                                      <FaCheck />
                                    </i>
                                  </a>
                                </div>
                                <div className="p_list_info">
                                  Bid <b>0.005 ETH</b>
                                  <span>
                                    by <b>Mamie Barnett</b> at 6/14/2021, 5:40
                                    AM
                                  </span>
                                </div>
                              </div>

                              <div className="p_list">
                                <div className="p_list_pp">
                                  <a href="05_black-author.html">
                                    <img
                                      className="lazy"
                                      src={author1}
                                      alt=""
                                    />
                                    <i className="fa fa-check">
                                      <FaCheck />
                                    </i>
                                  </a>
                                </div>
                                <div className="p_list_info">
                                  Bid <b>0.004 ETH</b>
                                  <span>
                                    by <b>Nicholas Daniels</b> at 6/13/2021,
                                    5:03 AM
                                  </span>
                                </div>
                              </div>

                              <div className="p_list">
                                <div className="p_list_pp">
                                  <a href="05_black-author.html">
                                    <img
                                      className="lazy"
                                      src={author1}
                                      alt=""
                                    />
                                    <i className="fa fa-check">
                                      <FaCheck />
                                    </i>
                                  </a>
                                </div>
                                <div className="p_list_info">
                                  Bid <b>0.003 ETH</b>
                                  <span>
                                    by <b>Lori Hart</b> at 6/12/2021, 12:57 AM
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="tab-2">
                              <div className="p_list">
                                <div className="p_list_pp">
                                  <a href="05_black-author.html">
                                    <img
                                      className="lazy"
                                      src={author1}
                                      alt=""
                                    />
                                    <i className="fa fa-check">
                                      <FaCheck />
                                    </i>
                                  </a>
                                </div>
                                <div className="p_list_info">
                                  Bid <b>0.005 ETH</b>
                                  <span>
                                    by <b>Jimmy Wright</b> at 6/14/2021, 6:40 AM
                                  </span>
                                </div>
                              </div>

                              <div className="p_list">
                                <div className="p_list_pp">
                                  <a href="05_black-author.html">
                                    <img
                                      className="lazy"
                                      src={author1}
                                      alt=""
                                    />
                                    <i className="fa fa-check">
                                      <FaCheck />
                                    </i>
                                  </a>
                                </div>
                                <div className="p_list_info">
                                  Bid accepted <b>0.005 ETH</b>
                                  <span>
                                    by <b>Monica Lucas</b> at 6/15/2021, 3:20 AM
                                  </span>
                                </div>
                              </div>

                              <div className="p_list">
                                <div className="p_list_pp">
                                  <a href="05_black-author.html">
                                    <img
                                      className="lazy"
                                      src={author1}
                                      alt=""
                                    />
                                    <i className="fa fa-check">
                                      <FaCheck />
                                    </i>
                                  </a>
                                </div>
                                <div className="p_list_info">
                                  Bid <b>0.005 ETH</b>
                                  <span>
                                    by <b>Mamie Barnett</b> at 6/14/2021, 5:40
                                    AM
                                  </span>
                                </div>
                              </div>

                              <div className="p_list">
                                <div className="p_list_pp">
                                  <a href="05_black-author.html">
                                    <img
                                      className="lazy"
                                      src={author1}
                                      alt=""
                                    />
                                    <i className="fa fa-check">
                                      <FaCheck />
                                    </i>
                                  </a>
                                </div>
                                <div className="p_list_info">
                                  Bid <b>0.004 ETH</b>
                                  <span>
                                    by <b>Nicholas Daniels</b> at 6/13/2021,
                                    5:03 AM
                                  </span>
                                </div>
                              </div>

                              <div className="p_list">
                                <div className="p_list_pp">
                                  <a href="05_black-author.html">
                                    <img
                                      className="lazy"
                                      src={author1}
                                      alt=""
                                    />
                                    <i className="fa fa-check">
                                      <FaCheck />
                                    </i>
                                  </a>
                                </div>
                                <div className="p_list_info">
                                  Bid <b>0.003 ETH</b>
                                  <span>
                                    by <b>Lori Hart</b> at 6/12/2021, 12:57 AM
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="spacer-10"></div>
                          <h6>Price</h6>
                          <div className="nft-item-price">
                            <img src={ethImg} alt="" />
                            <span>{cardinfo.price}</span>
                            {/* ($253.67) */}
                          </div>
                          {/* <!-- Button trigger modal --> */}
                          <button
                            className="btn-main btn-lg"
                            // data-bs-toggle="modal"
                            // data-bs-target="#buy_now"
                            onClick={() => checkBuyConditions()}
                          >
                            Buy Now
                          </button>
                          {/* &nbsp;&nbsp;&nbsp;
                          <a
                            href="#"
                            className="btn-main btn-lg btn-light bid-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#place_a_bid"
                          >
                            Place a Bid
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </section>
      </div>
      {/* <!-- buy now --> */}
      <Dialog
        fullScreen={fullScreen}
        open={showbuyPopup}
        id="MainDiv"
        className="signout-modal"
        onClose={() => setShowbuyPopup(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-end">
          <div className="d-flex justify-content-end">
            <IoMdClose
              className="closeIconSty"
              onClick={() => [setShowbuyPopup(false)]}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>
              <div className="p-3 form-border">
                <h3>Checkout</h3>
                {CardData.map((v) => {
                  return (
                    <>
                      <span className="text-white">
                        You are about to purchase a{" "}
                        <b className="id-color-2">{v.NameofPainting}</b> from{" "}
                        <b className="id-color-2">
                          {v.UserDetails[0]?.firstname}{" "}
                          {v.UserDetails[0]?.lastname}
                        </b>
                      </span>
                    </>
                  );
                })}

                <div className="spacer-single"></div>
                {/* <h6>
                  Total quantity:{" "}
                  <span className="id-color-2">
                    {sessionStorage.getItem("token_balance")}
                  </span>
                </h6> */}
                {/* <h6>
                  Available quantity: <span className="id-color-2">10 </span>
                </h6> */}
                <h6>
                  Enter quantity{" "}
                  <span className="id-color-2">
                    &nbsp; {sessionStorage.getItem("token_balance")} available
                  </span>
                </h6>
                <input
                  type="text"
                  className="form-control"
                  defaultValue="1"
                  onChange={(e) => setBuyQuantity(e.target.value)}
                />
                <div className="spacer-single"></div>
                {/* <div className="de-flex">
                  <div>Your balance</div>
                  <div>
                    <b>{buyQuantity * CardData[0]?.price}ETH</b>
                  </div>
                </div> */}
                {/* <div className="de-flex">
                  <div>Service fee 2.5%</div>
                  <div>
                    <b>0.00325 ETH</b>
                  </div>
                </div> */}
                <div className="de-flex">
                  <div className="text-white">You will pay</div>
                  <div className="text-white">
                    <b>{buyQuantity * CardData[0]?.price} &nbsp;ETH</b>
                  </div>
                </div>
                <div className="spacer-single"></div>
                <button
                  className="btn-main btn-fullwidth"
                  onClick={() =>
                    handleNetworkSwitch(
                      "andrena",
                      CardData[0]?.fileid,
                      CardData[0]?.contractaddress,
                      buyQuantity
                    )
                  }
                >
                  Add funds
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* <!-- place a bid --> */}
      <div
        className="modal fade"
        id="place_a_bid"
        tabindex="-1"
        aria-labelledby="place_a_bid"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered de-modal">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              <div className="p-3 form-border">
                <h3>Place a Bid</h3>
                You are about to place a bid for <b>Red Ocean</b> from{" "}
                <b>Monica Lucas</b>
                <div className="spacer-single"></div>
                <h6>Your bid (ETH)</h6>
                <input
                  type="text"
                  name="bid_value"
                  id="bid_value"
                  className="form-control"
                  placeholder="Enter bid"
                />
                <div className="spacer-single"></div>
                <h6>
                  Enter quantity.{" "}
                  <span className="id-color-2">10 available</span>
                </h6>
                <input
                  type="text"
                  name="bid_qty"
                  id="bid_qty"
                  className="form-control"
                  value="1"
                />
                <div className="spacer-single"></div>
                <div className="de-flex">
                  <div>Your bidding balance</div>
                  <div>
                    <b>0.013325 ETH</b>
                  </div>
                </div>
                <div className="de-flex">
                  <div>Your balance</div>
                  <div>
                    <b>10.67856 ETH</b>
                  </div>
                </div>
                <div className="de-flex">
                  <div>Service fee 2.5%</div>
                  <div>
                    <b>0.00325 ETH</b>
                  </div>
                </div>
                <div className="de-flex">
                  <div>You will pay</div>
                  <div>
                    <b>0.013325 ETH</b>
                  </div>
                </div>
                <div className="spacer-single"></div>
                <a
                  href="05_black-wallet.html"
                  target="_blank"
                  className="btn-main btn-fullwidth"
                >
                  Place a bid
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- content close --> */}
      <ToastContainer />
      <Footer />
    </>
  );
}

export default DetailPage;
