import React, { useEffect, useState } from "react";
import "../CreateToken/CreateToken.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { FaCheck, FaRegHeart, FaTag, FaUnlock } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi2";
import $ from "jquery";
import { RiArrowDropDownLine } from "react-icons/ri";
import author1 from "../../Assets/images/author/author-1.jpg";
import static1 from "../../Assets/images/items-alt/static-1.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CgCloseO } from "react-icons/cg";
import { FaRegCopy } from "react-icons/fa";
import jQuery from "jquery";
import { API_DOMAIN } from "../../utils/GlobalConst";
import { RxCrossCircled } from "react-icons/rx";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function copyText(element) {
  console.log(jQuery(element).text());
  var $copyText = jQuery(element).text();
  var button = jQuery("#btn_copy_address");
  navigator.clipboard.writeText($copyText).then(
    function () {
      var originalText = button.text();
      console.log(originalText, button.text());
      button.html("Copied!");
      button.addClass("clicked");
      setTimeout(function () {
        button.html(originalText);
        button.removeClass("clicked");
      }, 750);
    },
    function () {
      button.html("Error");
    }
  );
}
function dropdown(e) {
  var obj = $(e + ".dropdown");
  var btn = obj.find(".btn-selector");
  var dd = obj.find("ul");
  var opt = dd.find("li");

  obj
    .on("mouseenter", function () {
      dd.show();
      $(this).css("z-index", 1000);
    })
    .on("mouseleave", function () {
      dd.hide();
      $(this).css("z-index", "auto");
    });

  opt.on("click", function () {
    dd.hide();
    var txt = $(this).text();
    opt.removeClass("active");
    $(this).addClass("active");
    btn.text(txt);
  });
}
function CreateToken() {
  useEffect(() => {
    $(".de_tab").find(".de_tab_content > div").hide();
    $(".de_tab").find(".de_tab_content > div:first").show();
    $("li").find(".v-border").fadeTo(150, 0);
    $("li.active").find(".v-border").fadeTo(150, 1);
    $(".de_nav li").on("click", function () {
      $(this).parent().find("li").removeClass("active");
      $(this).addClass("active");
      $(this).parent().parent().find(".v-border").fadeTo(150, 0);
      $(this).parent().parent().find(".de_tab_content > div").hide();
      var indexer = $(this).index(); //gets the current index of (this) which is #nav li
      $(this)
        .parent()
        .parent()
        .find(".de_tab_content > div:eq(" + indexer + ")")
        .fadeIn(); //uses whatever index the link has to open the corresponding box
      $(this).find(".v-border").fadeTo(150, 1);
    });
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const userid = sessionStorage.getItem("userid");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [popup, setPopup] = useState(false);
  const [contractAddress, setContractAddress] = useState("");
  const [planId, setPlanId] = useState("");
  const [fileId, setFileId] = useState("");
  const [openUploadimage, setOpenUploadimage] = useState(false);
  const [profileImg, setProfileImg] = useState([]);
  const [fileURL, setFileURL] = useState("");
  const [fixedPrice, setFixedPrice] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [paintingName, setPaintingName] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [paintingDesc, setPaintingDesc] = useState("");
  const [totalTokenOfPainting, setTotalTokenOfPainting] = useState("");
  const [artistTokenPercentage, setArtistTokenPercentage] = useState("");
  const [artistName, setartistName] = useState("");
  const [artistWallet, setArtistWallet] = useState("");
  const [marketingPersonTokenPercentage, setMarketingPersonTokenPercentage] =
    useState("");
  const [marketingPersonName, setMarketingPersonName] = useState("");
  const [marketingPersonWallet, setMarketingPersonWallet] = useState("");
  const [custodianTokenPercentage, setCustodianTokenPercentage] = useState("");
  const [custodianName, setCustodianName] = useState("");
  const [custodianWallet, setCustodianWallet] = useState("");
  const [generalSaleTokenPercentage, setGeneralSaleTokenPercentage] =
    useState("");
  const [generalSaleWallet, setGeneralSaleWallet] = useState("");
  const [method, setMethod] = useState("fixed");
  const [ProfileImageURL, setProfileImageURL] = useState([]);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [approxDate, setApproxDate] = useState(tomorrow);
  const tomorrowtwo = new Date();
  tomorrowtwo.setDate(tomorrowtwo.getDate() + 1);
  const [proceedDate, setProceedDate] = useState(tomorrowtwo);
  const createToken = () => {
    var API_URL = "https://api.moramba.io:8868/api/tnt/v1/smartcontract/create";
    let headerConfig = {
      headers: {
        accept: "application/json",
      },
    };
    var data = {
      ContractName: paintingName,
      tokenName: tokenName,
      tokenSymbol: "TNTNT",
      ContractDetails: "This is testing dynamic contract",
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create Token", response);
        if (response.status === 200) {
          setPopup(true);
          setContractAddress(response.data.data.deployedContractAddress);
          setPlanId(response.data.data.PlanID);

          setFileId(response.data.data.fileID);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  };

  const handleProfileImgUpload = () => {
    // console.log(img)
    var API_URL = API_DOMAIN + "image/upload";
    var formData = new FormData();
    formData.append("att_file", profileImg[0]);
    console.log(formData);

    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, formData, headerConfig)
      .then(function (response) {
        console.log("Profile Img Upload", response);
        setFileURL(response.data.data.filePath);
        console.log(response.data.data.filePath);
        if (response.status === 200) {
          setOpenUploadimage(false);
          // handleUpdateProfileurl(response?.data?.data?.Location);
          // handleCloseProfileImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          // toast.error(error.response.data.message);
          setTimeout(() => {
            // setLoading(false);
            // handleCloseProfileImagePopup();
            setProfileImg("");
          });
        }
      })
      .then(function () {});
  };
  const TokenMetaData = () => {
    var API_URL = API_DOMAIN + "image/imagedata";
    let headerConfig = {
      headers: {
        accept: "application/json",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      fileURL: fileURL,
      NumOfTokensForPainting: totalTokenOfPainting,
      method: method,
      price: fixedPrice,
      collectionName: collectionName,
      NameofPainting: paintingName,
      ArtistName: artistName,
      DescriptionofPainting: paintingDesc,
      peroftokenstoartist: artistTokenPercentage,
      WalletaddressofArtist: artistWallet,
      NameofMarketingperson: marketingPersonName,
      peroftokenstoMarketingperson: marketingPersonTokenPercentage,
      WalletaddressofMarketingperson: marketingPersonWallet,
      NameofCustodian: custodianName,
      peroftokenstoCustodian: custodianTokenPercentage,
      WalletaddressofCustodian: custodianWallet,
      Tokensforgenearalsale: generalSaleTokenPercentage,
      Walletforgenearalsale: generalSaleWallet,
      tokenname: tokenName,
      userid: sessionStorage.getItem("userid"),
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create Token", response);
        if (response.status === 200) {
          // createToken();
          setPopup(true);
          setContractAddress(response.data.data.contractaddress);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const getProfileData = () => {
    var API_URL = API_DOMAIN + `profile/get?userid=${userid}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("Profile Image ", response.data.data);
        var res = response.data.data;
        setProfileImageURL(res.ProfileURL);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        {/* <!-- section begin --> */}
        <section id="subheader" className="p-0">
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Tokenize Asset</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section close --> */}
        {/* <!-- section begin --> */}
        <section aria-label="section">
          <div className="container">
            <div className="row fadeIn">
              <div className="col-lg-7 offset-lg-1">
                <div
                  id="form-create-item"
                  className="form-border"
                  // method="post"
                  // action="email.php"
                >
                  <div className="field-set">
                    <h5>Upload file</h5>
                    <button
                      className="btn-main"
                      onClick={() => setOpenUploadimage(true)}
                    >
                      Upload File
                    </button>
                    {/* <div className="d-create-file">
                      <p id="file_name">
                        PNG, JPG, GIF, WEBP or MP4. Max 200mb.
                      </p>
                      <input
                        type="file"
                        onChange={(e) => [setProfileImg(e.target.files)]}
                      />
                      <button
                        type="button"
                        // id="get_file"
                        className="btn-main"
                        // value="Browse"
                        onClick={handleProfileImgUpload}
                      >
                        Browse
                      </button>
                    </div> */}

                    <div className="spacer-40"></div>

                    <h5>Select method</h5>
                    <div className="de_tab tab_methods">
                      <ul className="de_nav">
                        <li
                          className="active"
                          onClick={() => setMethod("fixed")}
                        >
                          <span>
                            <i className="fa fa-tag">
                              <FaTag />
                            </i>
                            Fixed price
                          </span>
                        </li>
                        <li onClick={() => setMethod("bid")}>
                          <span>
                            <i className="fa fa-users">
                              <HiUserGroup />
                            </i>
                            Open for bids
                          </span>
                        </li>
                      </ul>

                      <div className="de_tab_content">
                        <div id="tab_opt_1">
                          <h5>Price</h5>
                          <input
                            type="text"
                            name="item_price"
                            id="item_price"
                            className="form-control"
                            placeholder="Enter price for one item (ETH)"
                            onChange={(e) => setFixedPrice(e.target.value)}
                          />
                        </div>

                        <div id="tab_opt_2"></div>
                      </div>
                    </div>
                    {/* 
                    <div className="spacer-20"></div>

                    <div className="switch-with-title">
                      <h5>
                        <i className="fa fa- fa-unlock-alt id-color-2 icon_padlock">
                          <FaUnlock />
                        </i>
                        Unlock once purchased
                      </h5>
                      <div className="de-switch">
                        <input
                          type="checkbox"
                          id="switch-unlock"
                          className="checkbox"
                        />
                        <label for="switch-unlock"></label>
                      </div>
                      <div className="clearfix"></div>
                      <p className="p-info">
                        Unlock content after successful transaction.
                      </p>

                      <div className="hide-content">
                        <input
                          type="text"
                          name="item_unlock"
                          id="item_unlock"
                          className="form-control"
                          placeholder="Access key, code to redeem or link to a file..."
                        />
                      </div>
                    </div> */}

                    <div className="spacer-20"></div>

                    <h5>Choose collection</h5>
                    <p className="p-info">
                      This is the collection where your item will appear.
                    </p>

                    <div
                      id="item_collection"
                      className="dropdown fullwidth mb5"
                      onChange={(e) => setCollectionName(e.target.value)}
                    >
                      <select className="btn-selector w-100 profile-select">
                        Select Collection{" "}
                        <option value="createnew">Create New</option>
                        <option value="abstraction">Abstraction</option>
                        <option value="patternlicious">Patternlicious</option>
                        <option value="skecthify">Skecthify</option>
                        <option value="cartoonism">Cartoonism</option>
                        <option value="virtuland">Virtuland</option>
                        <option value="papercut">Papercut</option>
                      </select>
                    </div>
                    <h5>Name of painting</h5>
                    <input
                      type="text"
                      placeholder="Enter name of painting"
                      className="form-control"
                      onChange={(e) => setPaintingName(e.target.value)}
                    />

                    <div className="spacer-20"></div>
                    <h5>Token name </h5>
                    <input
                      type="text"
                      placeholder="Enter token name"
                      className="form-control"
                      onChange={(e) => setTokenName(e.target.value)}
                    />

                    <div className="spacer-20"></div>

                    <h5>Description of painting</h5>
                    <textarea
                      data-autoresize
                      className="form-control"
                      placeholder="Enter description of painting"
                      onChange={(e) => setPaintingDesc(e.target.value)}
                    ></textarea>

                    <div className="spacer-20"></div>

                    <h5>Number of tokens for this painting</h5>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter number of tookens for this painting"
                      onChange={(e) => setTotalTokenOfPainting(e.target.value)}
                    />

                    <div className="spacer-single"></div>

                    <div className="row mx-4">
                      <div className="col-lg-12">
                        <h5>Percentage(%) of token to artist</h5>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter percentage(%) of token to artist"
                          onChange={(e) =>
                            setArtistTokenPercentage(e.target.value)
                          }
                        />
                      </div>
                      <div className="row mx-4 mt-3">
                        <div className="col-lg-6">
                          <h5>Name of artist</h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter name of  artist"
                            onChange={(e) => setartistName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-6">
                          <h5>Wallet address of artist</h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter wallet address of artist"
                            onChange={(e) => setArtistWallet(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="spacer-single"></div>

                      <div className="col-lg-12">
                        <h5>Percentage(%) of token to marketing person</h5>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter percentage(%) of token to marketing person"
                          onChange={(e) =>
                            setMarketingPersonTokenPercentage(e.target.value)
                          }
                        />
                      </div>
                      <div className="row mx-4 mt-3">
                        <div className="col-lg-6">
                          <h5>Name of marketing person</h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter name of  marketing person"
                            onChange={(e) =>
                              setMarketingPersonName(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-lg-6">
                          <h5>Wallet address of marketing person</h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter wallet address of marketing person"
                            onChange={(e) =>
                              setMarketingPersonWallet(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="spacer-single"></div>

                      <div className="col-lg-12">
                        <h5>Percentage(%) of token to custodian</h5>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter percentage(%) of token to custodian"
                          onChange={(e) =>
                            setCustodianTokenPercentage(e.target.value)
                          }
                        />
                      </div>
                      <div className="row mx-4 mt-3">
                        <div className="col-lg-6">
                          <h5>Name of custodian</h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter name of  custodian"
                            onChange={(e) => setCustodianName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-6">
                          <h5>Wallet address of custodian</h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter wallet address of custodian"
                            onChange={(e) => setCustodianWallet(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="spacer-single"></div>

                      <div className="col-lg-12">
                        <h5>Percentage(%) of token for general sale</h5>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter percentage(%) of token to custodian"
                          onChange={(e) =>
                            setGeneralSaleTokenPercentage(e.target.value)
                          }
                        />
                      </div>
                      <div className="row mx-4 mt-3">
                        <div className="col-lg-6">
                          <h5>Wallet address for general sale</h5>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter wallet address  for general sale"
                            onChange={(e) =>
                              setGeneralSaleWallet(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="spacer-20"></div>
                    <div className="d-flex flex-wrap gap-3">
                      <div>
                        <h5>Approx Date of Distribution </h5>
                        <DatePicker
                          // className="form-control dateInputSty h_50"
                          className="form-control datepickerSty"
                          minDate={tomorrow}
                          selected={approxDate}
                          dateFormat="dd MMM,yyyy"
                          onChange={(date) => [setApproxDate(date)]}
                        />
                      </div>
                      <div>
                        <h5>
                          Distribution of proceeds to Token owners as of Date
                        </h5>
                        <DatePicker
                          // className="form-control dateInputSty h_50"
                          className="form-control datepickerSty"
                          minDate={tomorrowtwo}
                          selected={proceedDate}
                          dateFormat="dd MMM,yyyy"
                          onChange={(date) => [setProceedDate(date)]}
                        />
                      </div>
                    </div>
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Enter number of tookens for this painting"
                      // onChange={(e) => setTotalTokenOfPainting(e.target.value)}
                    /> */}
                    <br />
                    <input
                      type="button"
                      id="submit"
                      className="btn-main mt-4"
                      onClick={TokenMetaData}
                      value="Create Item"
                    />
                    <div className="spacer-single"></div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 col-xs-12">
                <h5>Preview item</h5>
                <div className="nft__item">
                  <div
                    className="de_countdown"
                    // data-year="2022"
                    // data-month="6"
                    // data-day="16"
                    // data-hour="8"
                    style={{ backgroundColor: "#FEF006", color: "white" }}
                  >
                    <>0h 0m 0s</>
                  </div>
                  <div className="author_list_pp">
                    <Link href="#">
                      <img
                        className="lazy"
                        src={
                          ProfileImageURL === "" ||
                          ProfileImageURL === undefined
                            ? author1
                            : ProfileImageURL
                        }
                        alt=""
                      />
                      <i className="fa fa-check">
                        <FaCheck />
                      </i>
                    </Link>
                  </div>
                  <div className="nft__item_wrap">
                    <Link href="#">
                      <img
                        src={fileURL === "" ? static1 : fileURL}
                        className="lazy nft__item_preview"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="nft__item_info">
                    <Link href="#" className="LinkText">
                      <h4>{paintingName}</h4>
                    </Link>
                    {/* <div className="nft__item_click">
                      <span></span>
                    </div> */}
                    <div className="nft__item_price">
                      {fixedPrice} ETH
                      {/* <span>1/20</span> */}
                    </div>
                    {/* <div className="nft__item_action">
                      <Link href="#" className="LinkText">
                        Place a bid
                      </Link>
                    </div>
                    <div className="nft__item_like">
                      <i className="fa fa-heart">
                        <FaRegHeart />
                      </i>
                      <span>50</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {popup === true ? (
          <>
            <Dialog
              fullScreen={fullScreen}
              open={popup}
              id="MainDiv"
              className="signout-modal"
              onClose={() => setPopup(false)}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title" className="text-end">
                <div className="d-flex justify-content-between">
                  <h3 className="text-center">
                    TNT contract created successfully!!
                  </h3>{" "}
                  <CgCloseO
                    className="closeIconSty"
                    onClick={() => [setPopup(false)]}
                  />
                </div>

                <Divider />
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <div className="d-flex justify-content-start gap-3">
                    <div>
                      <span className="text-white">
                        {" "}
                        contract address:{" "}
                        <span id="contractaddress">
                          {" "}
                          {contractAddress}{" "}
                        </span>{" "}
                      </span>
                      <button
                        id="btn_copy_address"
                        title="Copy Text"
                        className="me-3"
                        onClick={() => copyText("#contractaddress")}
                      >
                        Copy
                      </button>
                    </div>
                  </div>

                  <p className="text-center">
                    {" "}
                    <p className="text-white">view contract</p>{" "}
                    <Link
                      to={`https://andrena-devnet.calderaexplorer.xyz/address/${contractAddress}`}
                      target="_blank"
                    >
                      here{" "}
                    </Link>{" "}
                  </p>
                </DialogContentText>
                <Divider />
              </DialogContent>
              <div className="d-flex justify-content-center gap-4 mb-3">
                <button
                  className="popup_btn_yes"
                  onClick={() => [navigate("/explore")]}
                >
                  Done
                </button>
              </div>
            </Dialog>
          </>
        ) : (
          <></>
        )}
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openUploadimage}
        onClose={() => setOpenUploadimage(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="text-center"
        ></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="d-flex justify-content-between mt-2">
              <h5>Upload file</h5>
              <RxCrossCircled
                className="cross-icon text-white"
                onClick={() => setOpenUploadimage(false)}
              />
            </div>

            <div className="d-create-file">
              <p>PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
              <input
                type="file"
                className="text-white"
                onChange={(e) => [setProfileImg(e.target.files)]}
              />
            </div>
            <br />
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center gap-3 mb-3">
            <button
              className="btn-main cancel-btn"
              onClick={() => setOpenUploadimage(false)}
            >
              Cancel
            </button>
            <button className="btn-main" onClick={handleProfileImgUpload}>
              Save
            </button>
          </div>
        </>
      </Dialog>
      <Footer />
    </>
  );
}

export default CreateToken;
