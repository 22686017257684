import React, { useState } from "react";
import "../Register/Register.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "../../Assets/css/coloring.css";
import "../../Assets/css/de-black.css";
import "../../Assets/css/style.css";
import "../../Assets/css/bootstrap.min.css";
import "../../Assets/css/plugins.css";
import "../../Assets/css/coloring-gradient.css";
import "../../Assets/css/bootstrap.rtl.min.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import { API_DOMAIN } from "../../utils/GlobalConst";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/Validation";

function Register() {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [passsword, setPasssword] = useState("");
  const [CPassword, setCPassword] = useState("");
  const [registerBtn, setRegisterBtn] = useState(false);
  const [passVisibility, setPassVisibility] = useState(false);
  const [CPassVisibility, setCPassVisibility] = useState(false);
  const [openRegisterEmailInfo, setOpenRegisterEmailInfo] = useState(false);

  // errors
  const [fNameErr, setFNameErr] = useState("");
  const [lNameErr, setLNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [CPassErr, setCPassErr] = useState("");
  const [registerErr, setRegisterErr] = useState("");
  const [mobileerr, setMobileerr] = useState("");

  const changeVisibility = (e) => {
    e.preventDefault();
    setPassVisibility(!passVisibility);
  };
  const changeCVisibility = (e) => {
    e.preventDefault();
    setCPassVisibility(!CPassVisibility);
  };

  const RegValidationHandler = () => {
    var isValidReg = true;

    if (!firstName) {
      isValidReg = false;
      setFNameErr("*Please Enter First Name!");
    }
    if (!lastName) {
      isValidReg = false;
      setLNameErr("*Please Enter Last Name!");
    }
    if (email === "" && mobileNumber === "") {
      isValidReg = false;
      setMobileerr("*Please Enter Phone Number!");
      setEmailErr("*Please Enter Email!");
    }
    // if (!email) {
    //   isValidReg = false;
    //   setEmailErr(<>* {text_err_email}!</>);
    // }
    if (mobileNumber !== "") {
      isValidReg = true;
      setEmailErr("");
    }
    if (email !== "" && EMAIL_REGEX.test(email) === false) {
      isValidReg = false;
      setEmailErr("*Please Enter Valid Email!");
      setMobileerr("");
    }

    if (EMAIL_REGEX.test(email) === true) {
      isValidReg = true;
      setEmailErr("");
      setMobileerr("");
    }
    if (!passsword) {
      isValidReg = false;
      setPassErr("*Please Enter Password!");
    }
    if (passsword && !PASSWORD_REGEX.test(passsword) === true) {
      isValidReg = false;
      setPassErr("*Please Enter Strong Password!");
    }
    if (!CPassword) {
      isValidReg = false;
      setCPassErr("*Please Enter Confirm Password!");
    }
    if (passsword !== CPassword) {
      isValidReg = false;
      setCPassErr("*Password & Confirm Password Don't Match!");
    }
    setRegisterBtn(false);
    return isValidReg;
  };

  const RegisterHandler = () => {
    if (RegValidationHandler()) {
      setRegisterBtn(true);
      var API_URL = API_DOMAIN + "user/createuser";

      let headerConfig = {
        headers: {
          accept: "application/json",
        },
      };
      var data = {
        email: email,
        password: passsword,
        role: "role",
        firstname: firstName,
        lastname: lastName,
        mobile: mobileNumber,
        country: "India",
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("reg res***", response);
          if (response.data.status === true) {
            setOpenRegisterEmailInfo(true);
            setRegisterBtn(false);
            // navigate("/login");
            // toast.success(response?.data?.message, {
            //   autoClose: 1000,
            //   theme: "light",
            // });
            // setTimeout(() => {
            //   handleOpenLogin();
            // }, [3000]);
          }
        })
        .catch(function (error) {
          setRegisterBtn(false);
          console.log(error.response.data);
          setRegisterErr("* " + error.response.data.message);
        })
        .then(function () {});
    }
  };
  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (registerBtn) {
        return;
      }
      RegisterHandler();
    }
  };
  const handleCloseRegisterEmailInfo = () => {
    setOpenRegisterEmailInfo(false);
    setTimeout(() => {
      navigate("/login");
    }, [1000]);
  };
  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content" onKeyPress={keyHandler}>
        <div id="top"></div>

        <section className="full-height relative no-top no-bottom pt-5 mt-2 pb-5">
          <div className="overlay-gradient t50 p-0">
            <div className="center-y relative">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-lg-2 offset-lg-12 fadeIn bg-color"
                    data-wow-delay=".5s"
                  ></div>
                  <div
                    className="col-lg-8 offset-lg-12 fadeIn bg-color"
                    data-wow-delay=".5s"
                  >
                    <div className="padding40 d-border">
                      <h3 className="mb10">Sign Up</h3>
                      <p>Don't have an account? Register now.</p>

                      <div
                        // name="contactForm"
                        // id="contact_form"
                        className="form-border"
                        // method="post"
                        // action="blank.php"
                      >
                        <div className="d-flex gap-5">
                          <div className="field-set w-100">
                            <input
                              type="text"
                              // name="name"
                              // id="name"
                              className="form-control inputstyle"
                              placeholder="First Name"
                              onChange={(e) => [
                                setFirstName(e.target.value),
                                setFNameErr(""),
                                setRegisterErr(""),
                              ]}
                            />
                            <span className="starsty">{fNameErr}</span>
                          </div>

                          <div className="field-set w-100">
                            <input
                              type="text"
                              // name="name"
                              // id="name"
                              className="form-control"
                              placeholder="Last Name"
                              onChange={(e) => [
                                setLastName(e.target.value),
                                setLNameErr(""),
                                setRegisterErr(""),
                              ]}
                            />
                            <span className="starsty">{lNameErr}</span>
                          </div>
                        </div>
                        <div className="d-flex gap-5 mt-3">
                          <div className="field-set w-100">
                            <input
                              type="text"
                              // name="email"
                              // id="email"
                              className="form-control"
                              placeholder="Email Address"
                              onChange={(e) => [
                                setEmail(e.target.value),
                                setEmailErr(""),
                                setMobileerr(""),
                                setRegisterErr(""),
                              ]}
                            />
                            <span className="starsty">{emailErr}</span>
                          </div>
                          <div className="field-set w-100">
                            <input
                              type="text"
                              // name="phoneno"
                              // id="phoneno"
                              className="form-control"
                              placeholder="Phone Number"
                              onChange={(e) => [
                                setMobileNumber(e.target.value),
                                setMobileerr(""),
                                setEmailErr(""),
                                setRegisterErr(""),
                              ]}
                            />
                            <span className="starsty">{mobileerr}</span>
                          </div>
                        </div>
                        <div className="d-flex gap-5 mt-3">
                          <div className="field-set w-100">
                            <div className="loc-group d-flex position-relative">
                              <input
                                type={passVisibility ? "text" : "password"}
                                // name="password"
                                // id="password"
                                className="form-control inputstyle"
                                placeholder="password"
                                onChange={(e) => [
                                  setPasssword(e.target.value),
                                  setPassErr(""),
                                  setRegisterErr(""),
                                ]}
                              />
                              <span className="pass-show-eye">
                                <button
                                  className="reg-eye"
                                  onClick={(e) => changeVisibility(e)}
                                >
                                  {passVisibility === true ? (
                                    <BsEyeFill />
                                  ) : (
                                    <BsEyeSlashFill />
                                  )}
                                </button>
                              </span>
                            </div>

                            <span className="starsty">{passErr}</span>
                          </div>
                          <div className="field-set w-100">
                            <div className="loc-group d-flex position-relative">
                              <input
                                type={CPassVisibility ? "text" : "password"}
                                // name="password"
                                // id="password"
                                className="form-control"
                                placeholder="Confirm Password"
                                onChange={(e) => [
                                  setCPassword(e.target.value),
                                  setCPassErr(""),
                                  setRegisterErr(""),
                                ]}
                              />
                              <span className="pass-show-eye">
                                <button
                                  className="reg-eye"
                                  onClick={(e) => changeCVisibility(e)}
                                >
                                  {CPassVisibility === true ? (
                                    <BsEyeFill />
                                  ) : (
                                    <BsEyeSlashFill />
                                  )}
                                </button>
                              </span>
                            </div>
                            <span className="starsty">{CPassErr}</span>
                          </div>
                        </div>
                        <center>
                          <div className="field-set mt-3 w-25">
                            <button
                              // type="submit"
                              // id="send_message"
                              // value="Submit"
                              className="btn btn-main btn-fullwidth color-2"
                              onClick={RegisterHandler}
                            >
                              Submit
                            </button>
                          </div>
                          <b>
                            {" "}
                            <center className="mt-2">
                              <span className="starsty mt-2">
                                {registerErr}
                              </span>
                            </center>
                          </b>
                        </center>

                        <div className="clearfix"></div>

                        <div className="spacer-single"></div>
                      </div>
                      <p className="text-center">
                        Already have an account?{" "}
                        <Link to="/login" className="LinkText">
                          Sign In<span></span>
                        </Link>
                        .
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-lg-2 offset-lg-12 fadeIn bg-color"
                    data-wow-delay=".5s"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openRegisterEmailInfo}
        onClose={() => setOpenRegisterEmailInfo(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <center>
            <FcFeedback className="Email-icon" />
          </center>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">Please Verify Your Account</h3>
            <h5 className="text-center">
              We have sent you a link on your registered email or phone no. to
              verify your Account.
            </h5>
            <h6 className="text-center">
              Please check your Spam or Junk folder
            </h6>
            <br />
            {/* <p className="text-center">
              <Link to="/support" className="text_account">
                <u>Resend verification link</u>
              </Link>
            </p> */}
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center mb-3">
            <button
              className="popup_btn_yes "
              onClick={handleCloseRegisterEmailInfo}
            >
              Okay
            </button>
          </div>
        </>
      </Dialog>
      <Footer />
    </>
  );
}

export default Register;
